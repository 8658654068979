import React from "react";
import { Input } from "antd";
import { useRealmApp } from "../utils/RealmApp";
import { getNominatorByMembershipId } from "../services/foals";
import { useField } from "formik";
const { Search } = Input;

export const MembershipSearch = ({
  onSearchResult,
  name,
  isConfirmed,
  prevalue,
  attempts,
}) => {
  const app = useRealmApp();
  const [field] = useField(name);
  const fetchNominator = async (app, value) => {
    const data = await getNominatorByMembershipId(app, value);
    onSearchResult(data);
  };

  if (prevalue && attempts <= 0) {
    attempts++;
    fetchNominator(app, prevalue);
  }

  const onSearch = (value) => {
    if (!value) return;
    fetchNominator(app, value);
  };

  return (
    <div>
      <Search
        {...field}
        placeholder="Search by Membership ID"
        onSearch={onSearch}
        enterButton
      />
    </div>
  );
};
