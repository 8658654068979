import React, { useState } from "react";
import { Button, Modal, Descriptions, Col, Space, notification } from "antd";
import {
  InputNumber,
  Input,
  SubmitButton,
  Select,
  Form,
  Checkbox,
} from "formik-antd";
import { Formik } from "formik";
import { useRealmApp } from "../utils/RealmApp";
import axios from "axios";
import "../App.css";

const { TextArea } = Input;

export const EntryErrorForm = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 5,
    });
  };

  const app = useRealmApp();

  return (
    <>
      {contextHolder}

      <Formik
        initialValues={{
          registered_number: "",
          registered_name: "",
          yob: "",
          sire: "",
          dam: "",
          name: "",
          email: "",
          phone: "",
          details: "",
        }}
        onSubmit={async (values, actions) => {
          console.log(values);
          // Create payload and pass to SendGrid template when complete
          try {
            let email = await axios.post(
              `${process.env.REACT_APP_MANAGEMENT_API_URL}/entry/message`,
              {
                registered_number: values.registered_number,
                registered_name: values.registered_name,
                yob: values.yob,
                sire: values.sire,
                dam: values.dam,
                name: values.name,
                email: values.email,
                phone: values.phone,
                details: values.details,
              }
            );
            if (email.data.code.code < 200 || email.data.code.code >= 300) {
              openNotificationWithIcon(
                "error",
                "Email",
                "We were unable to process your email request. Please try again or reach out to the support team for help.",
                "bottomRight"
              );
            } else {
              openNotificationWithIcon(
                "success",
                "Email Sent",
                "We've received your email and someone will get back to you within 48 hours. We look forward to assisting you!",
                "bottomRight"
              );
              actions.resetForm();
            }
          } catch (e) {
            console.log(e);
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.registered_number) {
            errors.registered_number = "required";
          }
          if (!values.registered_name) {
            errors.registered_name = "required";
          }
          if (!values.email) {
            errors.email = "required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.yob) {
            errors.yob = "required";
          }
          if (!values.sire) {
            errors.sire = "required";
          }
          if (!values.dam) {
            errors.dam = "required";
          }
          if (!values.name) {
            errors.name = "required";
          }
          if (!values.email) {
            errors.email = "required";
          }
          if (!values.phone) {
            errors.phone = "required";
          }

          return errors;
        }}
        render={(formik) => (
          <div className="container">
            <Form
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
            >
              <h3>Please use this so we can help you sort this out.</h3>
              <Space direction="vertical" style={{ width: "100%" }}></Space>

              <br></br>

              <Form.Item
                label="Reg #"
                name="registered_number"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="registered_number" placeholder="Registration #" />
              </Form.Item>

              <Form.Item
                label=" Registered Name"
                name="registered_name"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="registered_name" placeholder="Registered Name" />
              </Form.Item>

              <Form.Item
                label="Year of Birth"
                name="yob"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="yob" placeholder="Year of Birth" />
              </Form.Item>

              <Form.Item
                label="Sire"
                name="sire"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="sire" placeholder="Sire" />
              </Form.Item>

              <Form.Item
                label="Dam"
                name="dam"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="dam" placeholder="Dam" />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="name" placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="email" placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="phone" placeholder="Phone #" />
              </Form.Item>

              <Form.Item label="Details" name="details" hasFeedback={true}>
                <TextArea
                  rows={4}
                  name="details"
                  placeholder="Provide any additional info"
                />
              </Form.Item>

              <Form.Item label="" name="submit">
                <Button.Group size="large">
                  <SubmitButton
                    style={{
                      backgroundColor: "#a36b53",
                      borderColor: "#a36b53",
                      margin: "1rem 1rem 1rem 17rem ",
                    }}
                    disabled={false}
                  >
                    Send Email
                  </SubmitButton>
                </Button.Group>
              </Form.Item>
            </Form>
          </div>
        )}
      />
    </>
  );
};
