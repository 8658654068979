export const getDamSire = async (app, damSireId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const damSiresCollection = mongo.db("legends").collection("damSires");

    const damSire = await damSiresCollection.findOne({
      _id: { $oid: damSireId },
    });
    return damSire;
  } catch (error) {
    console.error("Error fetching DamSire:", error);
  }
};
