import { Select } from "formik-antd";
import { useEffect, useState } from "react";
import { useRealmApp } from "../utils/RealmApp";
import { getAllEvents } from "../services/events";

export const EventSelect = ({ getSelectedEvent }) => {
  const [events, setEvents] = useState([]);
  const app = useRealmApp();

  useEffect(() => {
    fetchEvents(app);
  }, []);

  function fetchEvents(app) {
    getAllEvents(app).then(({ events }) => {
      setEvents(events);
    });
  }

  const onChangeHandler = async (selected) => {
    const eventId = selected.id;
    const selectedEvent = events.find(
      (eachEvent) => eventId === eachEvent._id.toString()
    );
    getSelectedEvent(selectedEvent);
  };

  return (
    <Select
      optionLabelProp="children"
      name="event"
      showSearch
      placeholder="Search by Event Name"
      filterOption={(input, option) =>
        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(selected, opt) => {
        onChangeHandler(opt);
      }}
    >
      {events.map((eachEvent) => {
        return (
          <Select.Option
            id={eachEvent._id.toString()}
            key={eachEvent._id.toString()}
            value={eachEvent._id.toString()}
            name={eachEvent.name.toString()}
          >
            {eachEvent.name} - {formatDate(new Date(eachEvent.date))}
          </Select.Option>
        );
      })}
    </Select>
  );
};

function formatDate(date) {
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}
