import { Breadcrumb} from 'antd';
import React from 'react';



const TLBreadCrumb = (props)  => {
  
  return (
      <Breadcrumb
        style={{
          margin: '16px 0',
        }}
      >
      </Breadcrumb>

)};
export default TLBreadCrumb;