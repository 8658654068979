import axios from 'axios';
const url = process.env.REACT_APP_MONGO_URL;

axios.defaults.baseURL = url;
axios.defaults.headers.common['Accept'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const axiosInstance = axios.create({
  baseURL: url,
  headers: {
    'Access-Control-Allow-Origin': '*', // Required for CORS support to work
    'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
