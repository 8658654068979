import React from 'react';
import {Descriptions, Divider, Tag} from "antd";

const SireRenewalInfo = ({values, errors}) => {
  return (

    <>
      <Descriptions
        size="small"
        column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
        title={`Stallion Information`}
      >
        <Descriptions.Item label={<b>Stallion Name</b>} name="name">
          {values?.name}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Birth Year</b>} name="birthYear">
          {values?.birthYear}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Registration Number</b>}>
          {values?.regNum}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Sire</b>}>
          {values?.sireName}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Dam </b>}>
          {values?.damName}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Dam Sire</b>}>
          {values?.damSireName}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Fee</b>}>
          {values?.fee ? '$' + values?.fee : ' '}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Semen Availability</b>}>
          {values?.semenAvailability.map((item, i, arr) => <span
            style={{marginRight: '5px'}}>{i !== arr.length - 1 ? item + ', ' : item}</span>)}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Country</b>}>
          {values?.country}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Contact Person</b>}>
          {values?.contact}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Junior</b>}>
          {values?.junior ? 'Yes' : 'No'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>First Crop Birth Year</b>}>
          {values?.firstCropBirthYear}
        </Descriptions.Item>


        <Descriptions.Item label={<b>Limited</b>}>
          {values?.limited ? 'Yes' : 'No'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Breeding Report</b>}>
          {values?.breedingReport?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Barrels</b>}>
          {values?.barrels ? 'Yes' : 'No'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Poles</b>}>
          {values?.poles ? 'Yes' : 'No'}
        </Descriptions.Item>
      </Descriptions>

      <Divider plain/>
      <Descriptions
        size="small"
        column={1}
        title="Owner">
        <Descriptions.Item label={<b>Name</b>}>
          {values?.ownerName}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Address</b>}>
          {values?.ownerAddress1}{' '}{values?.ownerAddress3}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Email</b>}>
          {values?.ownerEmail}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Phone</b>}>
          {values?.ownerPhone}
        </Descriptions.Item>
      </Descriptions>
      <Divider plain/>
      <Descriptions
        size="small"
        column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
        title={`Rules`}
      >
        <Descriptions.Item label={''}>
          <a href="/2025_Stallion_Enrollment_Rules_.pdf" target="_blank" rel="noopener noreferrer">
            Tomorrow’s Legends Stallion Enrollment Rules
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={<b>Read and signed</b>}>
          {values?.renewalSigned ? 'Yes' : 'No'}
        </Descriptions.Item>
      </Descriptions>
      <Divider plain/>
      <Descriptions
        size="small"
        column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
        title="Marketing">
        <Descriptions.Item label={<b>Station Name</b>}>
          {values?.stationName}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Address</b>}>
          {values?.stationAddress1}{' '}{values?.stationAddress3}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Email</b>}>
          {values?.stationEmail}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Phone</b>}>
          {values?.stationPhone}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Website</b>}>
          {values?.stationWebsite}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Stallion Page Website</b>}>
          {values?.website}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Social Media Link</b>}>
          {values?.socialMedia}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Marketing Paragraph</b>}>
          {values?.marketingParagraph}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Papers</b>}>
          {values?.papers?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Profile Pictures</b>}>
          {values?.profilePicture?.name || 'Not Uploaded'}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Head Pictures</b>}>
          {values?.headPicture?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Video</b>}>
          {values?.video?.name || 'Not Uploaded'}
        </Descriptions.Item>
      </Descriptions>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {!!Object.entries(errors).length &&
          <Tag color={'error'}>Form is Incomplete. Please update Stallion Information</Tag>}
      </div>

    </>

  );
};

export default SireRenewalInfo;