import * as React from "react";
import {useState, useRef, useEffect} from "react";
import { Input, SubmitButton, Form } from "formik-antd";
import { Formik } from "formik";
import {
  Typography,
  Breadcrumb,
  Row,
  Divider,
  Space,
  Col,
  Card,
  Tag,
  Button,
  notification,
  Select,
  Descriptions,
  Segmented
} from "antd";
import { useRealmApp } from "../utils/RealmApp";
import { getOwner } from "../services/owners";
import { getDam } from "../services/dams";
import { getSire } from "../services/sires";
import OwnerFoalNotFoundModal from "../components/RegLookupFoalNotFoundModal";
import OwnerFoalFoundModal from "../components/RegLookupFoalFoundModal";
import { getAllSortedSires } from "../services/sires";
import { FoalSearch } from "../components/FoalSearch";
import axios from "axios";
import { MembershipLookupForm } from "./MembershipLookupForm";
import { getFoalByInfo } from "../services/foals";
import { OwnerLookupForm } from "./OwnerLookupForm";
import {UpOutlined} from "@ant-design/icons";
import useResponsive from "../hooks/useResponsive";
const { Title } = Typography;

export const RegLookupForm = () => {
  const app = useRealmApp();
  //useState values here
  const [foal, setFoal] = useState({});
  const [result, setResult] = useState({});
  const [owner, setOwner] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sire, setSire] = useState({});
  const [dam, setDam] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const [fileUpload, setFileUpload] = useState({});
  const [progress, setProgress] = useState(0);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [isResultHorse, setIsResultHorse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFoalModalOpen, setIsFoalModalOpen] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [sires, setSires] = useState([]);
  const divNom = useRef(null);
  const divOwner = useRef(null);
  const formRef = useRef();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [searchFormOption, setSearchFormOption] = useState('Registration Number');

  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useResponsive();

  const isMobile = isXs || isSm;

  // Foal Not Found Modal
  const openModal = () => {
    setIsConfirmed(false);
    setIsModalOpen(true);
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Foal  Found Modal
  const openFoalModal = () => {
    setIsFoalModalOpen(true);
  };

  const closeFoalModal = () => {
    setSire("");
    setIsFoalModalOpen(false);
    setResult(null);
    formRef.current.resetForm();
  };

  React.useEffect(() => {
    getAllSires();
  }, []);

  const getAllSires = async () => {
    let sires = await getAllSortedSires(app);
    setSires(sires);
  };

  const handleSireChange = (e) => {
    setSire(e);
  };

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 0,
    });
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    setFileUpload({});
    try {
      const { onSuccess, onError, file, onProgress } = options;

      const fmData = new FormData();
      const config = {
        headers: { "Content-Type": file.type },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: { prefix: "papers-test", name: file.uid, filetype: file.type },
        }
      );

      const res = await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const handleSearchResult = async (data) => {
    setResult(data);
    setIsModalOpen(data === "none"); // Show modal if result is "none"
    if (typeof data === "object") {
      setIsResultHorse(true);
      setFoal(data);
      const fetchedOwner = await getOwner(app, data.owner?.toString());
      setOwner(fetchedOwner);
      const fetchedDam = await getDam(app, data.dam?.toString());
      setDam(fetchedDam);
      const fetchedSire = await getSire(app, data.sire?.toString());
      setSire(fetchedSire);
    } else {
      setIsResultHorse(false);
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(false);
    closeFoalModal();
  };

  const handleOwnerChange = () => {
    setIsConfirmed(true);
    closeFoalModal();
  };

  const handleHorseIncorrect = () => {
    const to = process.env.REACT_APP_CONTACT_EMAIL;
    const subject = `Registration #: ${foal.regNum} - Horse Update Request`;
    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
      subject
    )}&body=Name%3A%0D%0AEmail%3A%0D%0APhone%20Number%3A%0D%0A%0D%0ADetails%3A`;
    window.open(mailtoLink);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setShowScrollButton(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} xl={8}>
          <Card title="Horse" bordered={false}>
            Look up Horse by Registration Number
            <div>
              <Button
                onClick={() => {
                  setSearchBy("regNum");
                  setTimeout(() => {
                    divNom?.current?.scrollIntoView({ behavior: "smooth" });
                  }, 100);
                }}
                style={{
                  backgroundColor: "#a36b53",
                  borderColor: "#a36b53",
                  color: "white",
                  marginTop: "0.5rem",
                }}
              >
                Look up Horse
              </Button>
            </div>
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card title="Nominator" bordered={false}>
            Search by Membership ID
            <div>
              <Button
                onClick={() => {
                  setSearchBy("nominator");
                  // reset search form option to default
                  setSearchFormOption("Registration Number")
                  setTimeout(() => {
                    divNom?.current?.scrollIntoView({ behavior: "smooth" });
                  }, 100);
                }}
                style={{
                  backgroundColor: "#a36b53",
                  borderColor: "#a36b53",
                  color: "white",
                  marginTop: "0.5rem",
                }}
              >
                Search by Nominator Information
              </Button>
            </div>
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card title="Owner" bordered={false}>
            Search by Name, Email, and Phone number
            <div>
              <Button
                onClick={() => {
                  setSearchBy("owner");
                  setTimeout(() => {
                    divOwner?.current?.scrollIntoView({ behavior: "smooth" });
                  }, 100);
                }}
                style={{
                  backgroundColor: "#a36b53",
                  borderColor: "#a36b53",
                  color: "white",
                  marginTop: "0.5rem",
                }}
              >
                Search by Owner Information
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Divider plain></Divider>
      <Row>
        <Col span={24}>
          {searchBy == "regNum" && (
            <Formik
              innerRef={formRef}
              initialValues={{
                yob: "",
                registration_number: "",
                sire: "",
                dam: "",
              }}
              onSubmit={async (values, actions) => {
                try {
                  console.log(values, 'values')
                  values.sire = sire;
                  const data = await getFoalByInfo(app, values);
                  if (data) {
                    handleSearchResult(data);
                  } else {
                    handleSearchResult("none");
                  }
                } catch (error) {}
              }}
              validate={(values) => {
                const errors = {};
                if (!values.yob) {
                  errors.yob = "required";
                }
                if (sire == "") {
                  errors.sire = "required";
                }
                if (!values.dam) {
                  errors.dam = "required";
                }
                return errors;
              }}
              render={(formik) => (
                <div ref={divNom}>
                  <Card>
                    <Segmented
                      defaultValue='Registration Number'
                      style={{
                        marginBottom: 20,
                      }}
                      onChange={(value) => setSearchFormOption(value)}
                      options={['Registration Number', 'Alternate Information']}
                    />
                    <Form
                      layout="vertical"
                      labelCol={{ span: 2 }}
                      wrapperCol={{ span: 14 }}
                    >
                      {searchFormOption === "Registration Number"  && <>
                        <Title level={5}>
                          Search if horse is enrolled by breed registration
                          number:
                        </Title>
                        <Space
                          direction="vertical"
                          style={{width: "100%"}}
                        ></Space>
                        <br></br>
                        <Form.Item
                          label="Reg #"
                          name="registration_number"
                          hasFeedback={true}
                          showValidateSuccess={true}
                        >
                          <FoalSearch
                            name="registration_number"
                            onSearchResult={handleSearchResult}
                          />
                        </Form.Item>
                      </>}
                      {searchFormOption === "Alternate Information" && <>
                        <Space>
                          <Title level={5}>
                            Search by alternate information :
                          </Title>
                        </Space>
                        <Tag color="red">* all fields required</Tag>
                        <Form.Item
                          hasFeedback={true}
                          rules={[{required: true}]}
                          showValidateSuccess={true}
                          label="Year of Birth"
                          name="yob"
                        >
                          <Input name="yob"/>
                        </Form.Item>
                        {/* Sire */}
                        <Form.Item
                          label="Sire Name"
                          name="sire"
                          hasFeedback={true}
                          showValidateSuccess={true}
                        >
                          <Select
                            name="sire"
                            placeholder="Sire Name"
                            showSearch
                            value={sire}
                            onChange={handleSireChange}
                            filterOption={(input, option) => {
                              return (
                                option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toString().toLowerCase()) >= 0
                              );
                            }}
                          >
                            {sires.map((sire) => (
                              <Select.Option
                                key={sire._id.toString()}
                                id={sire._id.toString()}
                                value={sire._id.toString()}
                              >
                                {sire.name} - {sire.yearEnrolled || ""}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {/* End Sire */}
                        <Form.Item
                          hasFeedback={true}
                          rules={[{required: true}]}
                          showValidateSuccess={true}
                          label="Dam Name"
                          name="dam"
                        >
                          <Input name="dam"/>
                        </Form.Item>
                        <Form.Item label="" name="submit">
                          <Button.Group size="medium">
                            <SubmitButton
                              style={{
                                backgroundColor: "#a36b53",
                                borderColor: "#a36b53",
                                marginTop: isMobile ? "auto" : "1rem",
                                marginLeft: isMobile ? "auto" : "6rem",
                              }}
                              disabled={false}
                            >
                              Search
                            </SubmitButton>
                          </Button.Group>
                        </Form.Item>
                      </>}
                      {result
                        ? isResultHorse && openFoalModal()
                        : result === "none" && openModal()}
                      <OwnerFoalNotFoundModal
                        isModalOpen={isModalOpen}
                        closeModal={closeModal}
                      />
                      <OwnerFoalFoundModal
                        isFoalModalOpen={isFoalModalOpen}
                        closeFoalModal={closeFoalModal}
                        openFoalModal={openFoalModal}
                        result={result}
                        owner={owner}
                        dam={dam}
                        sire={sire}
                        handleConfirm={handleConfirm}
                        handleOwnerChange={handleOwnerChange}
                      />
                      {isConfirmed ? (
                        <Row gutter={[16, 16]} style={{ marginBottom: "2rem" }}>
                          <Col
                            span={8}
                            style={{
                              marginLeft: "6rem",
                              marginRight: "0",
                            }}
                          >
                            <Descriptions
                              size="small"
                              column={1}
                              title="Horse Information"
                              style={{}}
                            >
                              <Descriptions.Item label={<b>Name</b>}>
                                {foal?.name}
                              </Descriptions.Item>

                              <Descriptions.Item label={<b>Sire</b>}>
                                {sire?.name}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Dam</b>}>
                                {dam?.name}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Year of Birth</b>}>
                                {foal?.yob}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Sex</b>}>
                                {foal?.sex?.toUpperCase()}
                              </Descriptions.Item>
                            </Descriptions>
                            <Button
                              onClick={handleHorseIncorrect}
                              style={{
                                backgroundColor: "#a36b53",
                                borderColor: "#a36b53",
                                color: "white",
                                marginTop: "0.5rem",
                              }}
                            >
                              Update Horse Info
                            </Button>
                          </Col>

                          <Col span={8} style={{}}>
                            <Descriptions
                              size="small"
                              column={1}
                              title="Current Owner Information"
                            >
                              <Descriptions.Item label={<b>Name</b>}>
                                {owner.name}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Address</b>}>
                                {owner.address1}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label={<b>City, State, Zipcode</b>}
                              >
                                {owner.address3}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Phone</b>}>
                                {owner.phone}
                              </Descriptions.Item>
                              <Descriptions.Item label={<b>Email</b>}>
                                {owner.email}
                              </Descriptions.Item>
                            </Descriptions>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </Card>
                </div>
              )}
            />
          )}
          {searchBy == "nominator" && (
            <div ref={divNom}>
              <Card>
                <MembershipLookupForm />
              </Card>
            </div>
          )}
          {searchBy == "owner" && (
            <div ref={divOwner}>
              <Card>
                <OwnerLookupForm />
              </Card>
            </div>
          )}
        </Col>
      </Row>
      {isMobile && showScrollButton && <div style={{
        position: 'fixed',
        bottom: "80px",
        right: "10px",
        transition: 'right 0.5s',
      }}>
        <Button type="ghost" onClick={scrollToTop}>
          <UpOutlined/>
        </Button>
      </div>}

    </>
  );
};
