import { useState, useEffect } from 'react';

const useResponsive = () => {
    const [isXs, setIsXs] = useState(window.innerWidth < 475);
    const [isSm, setIsSm] = useState(window.innerWidth >= 475 && window.innerWidth < 768);
    const [isMd, setIsMd] = useState(window.innerWidth >= 768 && window.innerWidth < 992);
    const [isLg, setIsLg] = useState(window.innerWidth >= 992 && window.innerWidth < 1200);
    const [isXl, setIsXl] = useState(window.innerWidth >= 1200 && window.innerWidth < 1600);
    const [isXxl, setIsXxl] = useState(window.innerWidth >= 1600);

    const handleResize = () => {
        const width = window.innerWidth;

        setIsXs(width < 475);
        setIsSm(width >= 475 && width < 768);
        setIsMd(width >= 768 && width < 992);
        setIsLg(width >= 992 && width < 1200);
        setIsXl(width >= 1200 && width < 1600);
        setIsXxl(width >= 1600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { isXs, isSm, isMd, isLg, isXl, isXxl };
};

export default useResponsive;
