import React from "react";
import { Input } from "antd";
import { useRealmApp } from "../utils/RealmApp";
import { getFoal } from "../services/foals";
import { useField } from "formik";
const { Search } = Input;

export const FoalSearch = ({
  onSearchResult,
  name,
  isConfirmed,
  prevalue,
  attempts,
}) => {
  const app = useRealmApp();
  const [field] = useField(name);
  const fetchFoal = async (app, value) => {
    const data = await getFoal(app, value);
    onSearchResult(data);
  };

  if (prevalue && attempts <= 0) {
    attempts++;
    fetchFoal(app, prevalue);
  }

  const onSearch = (value) => {
    if (!value) return;

    if (value.includes("PENDING")) {
      value = "pendinghorseinvalid";
    }

    fetchFoal(app, value);
  };

  return (
    <div>
      <Search
        {...field}
        placeholder="Search by Registration Number"
        onSearch={onSearch}
        enterButton
      />
    </div>
  );
};
