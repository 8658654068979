import * as React from "react";
import {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input, SubmitButton, Form } from "formik-antd";
import { Formik } from "formik";

import {
  Typography,
  Breadcrumb,
  Row,
  Space,
  Col,
  notification,
  Button,
  Alert,
  Descriptions,
  Divider,
} from "antd";
import { useRealmApp } from "../utils/RealmApp";
import { getOwner } from "../services/owners";
import { getDam } from "../services/dams";
import { getSire } from "../services/sires";
import FoalNotFoundModal from "../components/FoalNotFoundModal";
import FoalFoundModal from "../components/FoalFoundModal";
import axios from "axios";

import { FoalSearch } from "../components/FoalSearch";
import { EventSelect } from "../components/EventSelect";
import { RiderSelect } from "../components/RiderSelect";
import { createRider } from "../services/riders";
import { checkEntryForFoal, createEntry } from "../services/entries";
import InfoUpdateRequestModal from "../components/InfoUpdateRequestModal";

const { Title, Text, Paragraph } = Typography;

export const EntryForm = () => {
  const app = useRealmApp();
  //useState values here
  const [foal, setFoal] = useState({});
  const [foalEntry, setFoalEntry] = useState(null);
  const [isFoalEligible, setIsFoalEligible] = useState(true);
  const [result, setResult] = useState({});
  const [savedResult, setSavedResult] = useState({});
  const [owner, setOwner] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sire, setSire] = useState({});
  const [dam, setDam] = useState({});
  const [rider, setRider] = useState({});
  const [selectedEvent, setSelectedEvent] = useState({});
  const [riderInput, setRiderInput] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const horseRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();

  let navigate = useNavigate();

  // modals useState
  const [isResultHorse, setIsResultHorse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFoalModalOpen, setIsFoalModalOpen] = useState(false);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);

  const getSelectedEvent = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
  };

  const checkEvent = async() => {
    if (selectedEvent?._id && foal?._id) {
      const foundEntry = await checkEntryForFoal(app, selectedEvent?._id, foal?._id)
      setFoalEntry(foundEntry)
    }
    // validate rule for sire enrollment
    if (selectedEvent?.date && sire?.yearEnrolled) {
      const eventDate = new Date(selectedEvent?.date);
      if (sire.yearEnrolled > currentYear.toString() && eventDate.getTime() < new Date(`11/15/${currentYear.toString()}`).getTime()) {
        setIsFoalEligible(false);
      }else{
        setIsFoalEligible(true);
      }
    }
  }

  useEffect(()=>{
    checkEvent()
  }, [selectedEvent, foal])

  const handleRiderChange = (name, option) => {
    setRider(option);
    setRiderInput("");
  };

  const handleRiderInputChange = (value) => {
    setRiderInput(value);
    setRider(null);
  };

  // Foal Not Found Modal
  const openModal = () => {
    setIsConfirmed(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Foal Found Modal
  const openFoalModal = () => {
    setIsFoalModalOpen(true);
  };

  const closeFoalModal = () => {
    setIsFoalModalOpen(false);
    setResult(null);
  };

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 0,
    });
  };

  const handleSearchResult = async (data) => {
    setResult(data);
    // Show modal if result is "none"
    setIsModalOpen(data === "none");
    if (typeof data === "object") {
      setIsResultHorse(true);
      setFoal(data);
      const fetchedOwner = await getOwner(app, data.owner?.toString());
      setOwner(fetchedOwner);
      const fetchedDam = await getDam(app, data.dam?.toString());
      setDam(fetchedDam);
      const fetchedSire = await getSire(app, data.sire?.toString());
      setSire(fetchedSire);
    } else {
      setIsResultHorse(false);
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    setSavedResult(result);
    closeFoalModal();
  };

  const redirectToHorseUpdate = () => {
    window.open(`/foal-update?regnum=${foal.regNum}`, '_blank');
    closeFoalModal();
  }

  const initialValues = {
    event: "",
    foal: foal,
    owner: owner,
    email: "",
    draw: "",
    rider: "",
  };

  useEffect(() => {
    setTimeout(() => {
      horseRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [isConfirmed, isFoalModalOpen]);

  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          setLoading(true)
          let riderId;
          if (rider && Object.values(rider).length) {
            riderId = rider.id;
          } else {
            let riderName = riderInput || values.rider
            const newRider = await createRider(app, {
              name: riderName?.toUpperCase(),
            });
            riderId = newRider.insertedId.toString();
            setRider({ name: riderName, id: riderId });
          }

          const payload = {
            foal: foal._id,
            event: { $oid: values.event },
            owner: owner._id,
            email: values.email ? values.email.toUpperCase() : "",
            draw: values.draw ? values.draw.toUpperCase() : "",
            rider: { $oid: riderId },
            createdAt: new Date(),
            placement: null,
            time: null,
          };

          try {
            const success = await createEntry(app, payload);
            if (success !== undefined) {
              try {
                let email = await axios.post(
                  `${process.env.REACT_APP_MANAGEMENT_API_URL}/entry/receipt`,
                  {
                    foal: foal.name,
                    event: selectedEvent.name,
                    owner: owner.name,
                    email: values.email,
                    draw: values.draw,
                    rider: riderInput || rider?.name,
                  }
                );
                if (
                  email.data.code.every((c) => c.statusCode < 300 || c === "")
                ) {
                  openNotificationWithIcon(
                    "success",
                    "Entry",
                    `We have received your request for a TL Side-pot entry for ${foal.name}. We will send you a confirmation receipt by email. Good Luck!`,
                    "bottomRight"
                  );
                } else {
                  openNotificationWithIcon(
                    "error",
                    "Email",
                    "Your entry was accepted, but we are having trouble sending the confirmation email. Please reach out to the support team to receive your receipt. ",
                    "bottomRight"
                  );
                }
              } catch (e) {
                console.log(e);
                openNotificationWithIcon(
                  "error",
                  "Entry",
                  "Your entry was accepted, but we are having trouble sending the confirmation email. Please reach out to the support team to receive your receipt. ",
                  "bottomRight"
                );
              }
            } else {
              openNotificationWithIcon(
                "error",
                "Entry",
                "We did not receive your submission. Please try again or email our support box.",
                "bottomRight"
              );
            }
            setIsConfirmed(false);
          } catch (error) {
            openNotificationWithIcon(
              "error",
              "Entry",
              "We did not receive your submission. Please try again or email our support box.",
              "bottomRight"
            );
          }
          setLoading(false)
          actions.resetForm();
        }}
        validate={(values) => {
          const errors = {};

          if (!values.registration_number) {
            errors.registration_number = "required";
          }

          if (!values.event) {
            errors.event = "required";
          } else if (foalEntry){
          errors.event = `${foal.name} is already registered for this event.`;
          } else if (!isFoalEligible){
            errors.event = `${foal.name} cannot enter an event before 11/15/${currentYear.toString()}.`;
          }

          if (!values.email) {
            errors.email = "required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.rider) {
            errors.rider = "required";
          }

          return errors;
        }}
        render={(formik) => (
          <div className="container">
            <Form
              layout="vertical"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 14 }}
            >
              <Title level={4}>
                Please use this form to enter the TL Side-pot for TL Events
              </Title>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message="Entry Eligibility"
                  description={
                    <>
                      Horse must be enrolled with Tomorrow’s Legends to enter
                      event. You may check enrollment status here:{" "}
                      <Link to="/registration-lookup">Lookup</Link> If you need
                      to enroll your horse, please do so here{" "}
                      <Link to="/foal-enrollment">Enrollment</Link>. After
                      enrolling, Please allow 24 hours for the system to update
                      before attempting to enter a TL event.
                    </>
                  }
                  type="error"
                />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }}></Space>
              <Divider orientation="center"></Divider>
              <br></br>

              <Form.Item
                label="Reg #"
                name="registration_number"
                showValidateSuccess={true}
                hasFeedback={true}
              >
                <FoalSearch
                  name="registration_number"
                  onSearchResult={handleSearchResult}
                />
              </Form.Item>
              {result
                ? isResultHorse && openFoalModal()
                : result === "none" && openModal()}
              <FoalNotFoundModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
              />
              <FoalFoundModal
                isFoalModalOpen={isFoalModalOpen}
                closeFoalModal={closeFoalModal}
                openFoalModal={openFoalModal}
                result={result}
                owner={owner}
                dam={dam}
                sire={sire}
                handleConfirm={handleConfirm}
                handleHorseUpdate={redirectToHorseUpdate}
              />
                <InfoUpdateRequestModal
                  entity={"Owner"}
                  info={owner}
                  isModalOpen={isUpdateInfoModalOpen}
                  closeModal={()=> {
                    setIsUpdateInfoModalOpen(false)
                  }}
                />

              {isConfirmed ? (
                <div ref={horseRef}>
                  <Row
                    gutter={[16, 16]}
                    style={{ marginBottom: "2rem" }}
                    className="description"
                  >
                    <Col xs={24} md={10} lg={6} >
                      <Descriptions
                        size="small"
                        column={1}
                        title="Horse Information"
                        style={{}}
                      >
                        <Descriptions.Item label={<b>Name</b>}>
                          {foal?.name}
                        </Descriptions.Item>

                        <Descriptions.Item label={<b>Sire</b>}>
                          {sire?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Dam</b>}>
                          {dam?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Year of Birth</b>}>
                          {foal?.yob}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Sex</b>}>
                          {foal?.sex?.toUpperCase()}
                        </Descriptions.Item>
                      </Descriptions>
                      <Button
                        href={`/foal-update?regnum=${foal.regNum}`}
                        target="_blank"
                        style={{
                          backgroundColor: "#a36b53",
                          borderColor: "#a36b53",
                          color: "white",
                          marginTop: "0.5rem",
                        }}
                      >
                        Update Horse Info
                      </Button>
                    </Col>

                    <Col xs={24} md={10} lg={10}>
                      <Descriptions
                        size="small"
                        column={1}
                        title="Owner Information"
                      >
                        <Descriptions.Item label={<b>Name</b>}>
                          {owner.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Address</b>}>
                          {owner.address1}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>City, State, Zipcode</b>}>
                          {owner.address3}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Phone</b>}>
                          {owner.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Email</b>}>
                          {owner.email}
                        </Descriptions.Item>
                      </Descriptions>
                      <Button
                        style={{
                          marginTop: "15px",
                        }}
                        key="owner_change"
                        type="primary"
                        href={`/owner-change?regnum=${savedResult?.regNum}`}
                      >
                        Change Horse Ownership
                      </Button>
                      <br></br>
                      <Button
                        style={{
                          backgroundColor: "#a36b53",
                          borderColor: "#a36b53",
                          color: "white",
                          marginTop: "0.5rem",
                        }}
                        // href="https://www.tomorrowslegendsllc.com/infoupdate"
                        // target="_blank"
                        onClick={()=>setIsUpdateInfoModalOpen(true)}
                      >
                        Update Owner Info
                      </Button>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Event"
                    name="event"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <EventSelect getSelectedEvent={getSelectedEvent} />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="email" placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    label="Draw #"
                    name="draw"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="draw" placeholder="Draw # If Known" />
                  </Form.Item>

                  <Form.Item
                    label="Rider"
                    name="rider"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <RiderSelect
                      name="rider"
                      handleRiderChange={handleRiderChange}
                      handleRiderInputChange={handleRiderInputChange}
                    />
                  </Form.Item>

                  <Form.Item label="" name="submit">
                    <Button.Group size="large">
                      <SubmitButton
                        style={{
                          backgroundColor: "#a36b53",
                          borderColor: "#a36b53",
                          marginTop: "1rem",
                          marginLeft: "6rem",
                        }}
                        disabled={loading}
                      >
                        Submit Entry
                      </SubmitButton>
                    </Button.Group>
                  </Form.Item>
                </div>
              ) : (
                <></>
              )}
            </Form>
          </div>
        )}
      />
    </>
  );
};
