import * as React from "react";
import EnrollmentPage from "./pages/EnrollmentPage";
import HomePage from "./pages/HomePage";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import "./index.css";
import "./App.css";
import "antd/dist/antd.min.css";
import "./theme.less";
import { RealmAppProvider, useRealmApp } from "./utils/RealmApp";
import TLReceipt from "./modules/TLReceipt";
import { ConfigProvider } from "antd";
import appId from "./realm.json";
import * as Realm from "realm-web";
import EntryPage from "./pages/EntryPage";
import FoalNotFoundModal from "./components/FoalNotFoundModal";
import { EntryErrorForm } from "./components/EntryErrorForm";
import OwnerChangePage from "./pages/OwnerChangePage";
import RegLookupPage from "./pages/RegLookupPage";
import ResultsPage from "./pages/ResultsPage";
import EarningPage from "./pages/EarningPage";
import OwnerNominatorInfoUpdatePage from "./pages/OwnerNominatorInfoUpdatePage";
import FoalInfoUpdatePage from "./pages/FoalInfoUpdatePage";
import SireEnrollmentRenewalPage from "./pages/SireEnrollmentRenewalPage";
import SireTLReceipt from "./modules/SireTLReceipt";

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={process.env.REACT_APP_REALM_APPID || appId}>
      <App />
    </RealmAppProvider>
  );
}

function App() {
  const realmApp = useRealmApp();
  useEffect(() => {
    async function fetchMyAPI() {
      await realmApp.logIn(
        Realm.Credentials.apiKey(process.env.REACT_APP_MONGO_API_KEY)
      );
    }
    fetchMyAPI();
  }, []);
  return realmApp.currentUser ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<EnrollmentPage />} />
        <Route path="/foal-enrollment" element={<EnrollmentPage />} />
        <Route path="/sire-enrollment" element={<SireEnrollmentRenewalPage />} />
        <Route path="/enrolled" element={<TLReceipt />} />
        <Route path="/sire-enrolled" element={<SireTLReceipt />} />
        <Route path="/entry" element={<EntryPage />} />
        <Route path="/owner-change" element={<OwnerChangePage />} />
        <Route path="/foal-error" element={<FoalNotFoundModal />} />
        <Route path="/entry-error" element={<EntryErrorForm />} />
        <Route path="/registration-lookup" element={<RegLookupPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/earning" element={<EarningPage />} />
        <Route path="/info-update/:entity/:id" element={<OwnerNominatorInfoUpdatePage />} />
        <Route path="/foal-update" element={<FoalInfoUpdatePage />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <>Loading...</>
  );
}

ConfigProvider.config({
  prefixCls: "custom",
  theme: {
    primaryColor: "#25b864",
  },
});
