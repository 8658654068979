import React from 'react';
import { Button, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const InvalidLink = () => {

  return (
    <Card bordered={false}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CloseCircleOutlined style={{ color: "red", fontSize: "25px", marginBottom: "10px" }}/>
        <Title level={4}>
          The Link is Invalid or Expired
        </Title>

        <Button style={{ marginTop: "20px" }}>
          <Link to="/registration-lookup">
            Request New Link
          </Link>
        </Button>
      </div>
    </Card>
  );
};

export default InvalidLink;
