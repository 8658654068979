import React, { useState } from "react";
import OwnerInformation from "../components/OwnerInformation";
import { getOwnerByPersonalInfo } from "../services/foals";
import { useRealmApp } from "../utils/RealmApp";
import {
  Input,
  InputNumber,
  SubmitButton,
  Select,
  Form,
  Checkbox,
} from "formik-antd";
import { Field, Formik } from "formik";
import {
  Typography,
  Breadcrumb,
  Switch,
  Row,
  Divider,
  Statistic,
  List,
  Space,
  Col,
  Anchor,
  Tag,
  Button,
  message,
  notification,
  Progress,
  Upload,
  Alert,
  TreeSelect as $TreeSelect,
  Modal,
  Descriptions,
} from "antd";
import { MembershipSearch } from "../components/MembershipSearch";
import useResponsive from "../hooks/useResponsive";

const { Title, Text, Paragraph } = Typography;
export const OwnerLookupForm = () => {
  const [Owner, setOwner] = useState({});

  const { isXs, isSm } = useResponsive();

  const isMobile = isXs || isSm;

  const app = useRealmApp();
  const handleSearchResult = async (data) => {
    setOwner(data);
  };

  return (
    <div>
      <Formik
        initialValues={{
          ownerName: "",
          ownerEmail: "",
          ownerAddress: "",
        }}
        onSubmit={async (values, actions) => {
          try {
            const data = await getOwnerByPersonalInfo(app, values);
            if (data) {
              setOwner(data);
            } else {
              setOwner(data);
            }
          } catch (error) {}
        }}
        validate={(values) => {
          const errors = {};
          if (!values.ownerName) {
            errors.ownerName = "required";
          }
          if (!values.ownerEmail) {
            errors.ownerEmail = "required";
          }
          if (!values.ownerAddress) {
            errors.ownerAddress = "required";
          }
          return errors;
        }}
        render={(formik) => (
          <div>
            <Form
              layout="vertical"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 14 }}
            >
              <Space>
                <Title level={5}>Look up by personal information:</Title>
              </Space>
              <Tag color="red">* all fields required</Tag>
              <Form.Item
                hasFeedback={true}
                showValidateSuccess={true}
                label="Name"
                name="ownerName"
              >
                <Tag style={{ marginBottom: "4px" }} color="red">
                  *Must match Registration papers exactly*
                </Tag>
                <Input
                  name="ownerName"
                  placeholder="Name exactly as on your horse’s breed registration papers"
                />
              </Form.Item>
              <Form.Item
                hasFeedback={true}
                showValidateSuccess={true}
                label="Email"
                name="ownerEmail"
              >
                <Input name="ownerEmail" placeholder="Email" />
              </Form.Item>

              <Form.Item
                hasFeedback={true}
                showValidateSuccess={true}
                label="Address Line 1"
                name="ownerAddress"
              >
                <Input name="ownerAddress" placeholder="Street Address" />
              </Form.Item>
              <Form.Item label="" name="submit">
                <Button.Group size="medium">
                  <SubmitButton
                    style={{
                      backgroundColor: "#a36b53",
                      borderColor: "#a36b53",
                      marginTop: isMobile ? "auto" : "1rem",
                      marginLeft:  isMobile ? "auto" : "6rem",
                    }}
                    disabled={false}
                  >
                    Search
                  </SubmitButton>
                </Button.Group>
              </Form.Item>
            </Form>
            {Owner?.name && (
              <div>
                <Alert message="Owner Found!" type="success" />
                <OwnerInformation info={Owner} />
              </div>
            )}
            {!Owner && (
              <div>
                <Alert message={`Owner not found.`} type="error" />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
