import React, {useState} from 'react';
import SireRenewalForm from "./SireRenewalForm";
import SirePaymentForm from "../SirePaymentForm";
import axios from "axios";
import {getSireRenewal, updateSireRenewal} from "../../services/sireRenewals";
import {useRealmApp} from "../../utils/RealmApp";
import {notification} from "antd";
import {useNavigate} from "react-router-dom";

const SireRenewalIndex = ({renewalValues}) => {
  const app = useRealmApp();
  let navigate = useNavigate();


  const [step, setStep] = useState('renewal');
  const [renewalData, setRenewalData] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const skipPayment = renewalValues?.remainingBalance === 0

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };

  const onStepChange = (step) => {
    setStep(step)
  }

  const createRenewal = async (values) => {
    console.log(values, 'values')
    try {
      const sireRenewValues = {
        _id: values._id,
        sire: values.sireId,
        contact: values.contact?.toUpperCase(),
        semenAvailability: values.semenAvailability,
        stationName: values.stationName?.toUpperCase(),
        stationAddress1: values.stationAddress1?.toUpperCase(),
        stationAddress3: values.stationAddress3?.toUpperCase(),
        stationEmail: values.stationEmail?.toUpperCase(),
        stationPhone: values.stationPhone?.toUpperCase(),
        stationWebsite: values.stationWebsite?.toUpperCase(),
        website: values.website?.toUpperCase(),
        marketingParagraph: values.marketingParagraph?.toUpperCase(),
        socialMedia: values.socialMedia?.toUpperCase(),
        junior: values.junior,
        limited: values.limited,
        country: values.country,
        barrels: values.barrels,
        poles: values.poles,
        lastYearEnrolled: values.lastYearEnrolled,
        notes: values.notes,
        firstCropBirthYear: values.firstCropBirthYear,
        breedingReport: values.breedingReport,
        papers: values.papers,
        headPicture: values.headPicture,
        profilePicture: values.profilePicture,
        video: values.video,
        renewalSigned: values.renewalSigned
      }

      const result = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/sire-renewal/renewal`,
        sireRenewValues
      )

      const insertedId = result?.data?.renewal?.value?._id || result?.data?.renewal?.insertedId
      if (insertedId) {
        const newRenewal = await getSireRenewal(app, insertedId)
        setRenewalData(newRenewal[0])
        if (!skipPayment) {
          onStepChange('payment')
        }else{
          const updatePayload = {
            amountPaid: 0,
            paymentCreated: new Date(),
          }
          await updateSireRenewal(app, newRenewal[0]?._id, updatePayload);
          openNotificationWithIcon(
            "success",
            "Renewal",
            "Your renewal submitted successfully.",
            "bottomRight",
          );
          setTimeout(() => {
            navigate("/sire-enrolled", {
              state: {
                applicationType: 'Renewal',
                sireName: renewalValues?.name || '',
                totalPrice: 0,
                regNum: renewalValues?.regNum || '',
                birthYear: renewalValues?.birthYear || ''
              }
            });
          }, "3000")
        }
      }

    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Error",
        'Error occurred. Please try again.',
        "bottomRight",
      );
    }
  }

  return (
    <>
      {contextHolder}
      {step === 'renewal' &&
        <SireRenewalForm renewalValues={renewalValues} onStepChange={onStepChange} onSubmit={createRenewal} skipPayment={skipPayment}/>
      }
      {step === 'payment' &&
        <SirePaymentForm
          onStepChange={()=>setStep('renewal')}
          sireName={renewalData?.sire?.name}
          birthYear={renewalData?.sire?.birthYear}
          regNum={renewalData?.sire?.birthYear}
          data={renewalData}
          applicationType='Renewal'
          sireId={renewalData?.sire?._id}
        />
      }
    </>
  );
};

export default SireRenewalIndex;