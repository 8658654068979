export const getSire = async (app, sireId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const siresCollection = mongo.db("legends").collection("sires");

    const sire = await siresCollection.findOne({
      _id: { $oid: sireId },
    });

    return sire;
  } catch (error) {
    console.error("Error fetching Sire:", error);
  }
};

export const getAllSortedSires = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const siresCollection = mongo.db("legends").collection("sires");

    const sires = await siresCollection.find({}, { sort: { name: 1 } });
    return sires;
  } catch (error) {
    console.error("Error fetching Sire:", error);
  }
};

export const getSireByRegNum = async (app, regNum) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const siresCollection = mongo.db("legends").collection("sires");

    const sire = await siresCollection.findOne({regNum});
    return sire;
  } catch (error) {
    console.error("Error fetching Sire:", error);
  }
};
