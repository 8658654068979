import React, {Fragment, useState} from 'react';
import {Col, Row, Tag} from "antd";
import CreateOwnerModal from "../../components/CreateOwnerModal";
import {Checkbox, Form, Input} from "formik-antd";
import {getOwnerByPersonalInfo} from "../../services/foals";
import {useRealmApp} from "../../utils/RealmApp";
import OwnerFoundModal from "../../components/OwnerFoundModal";


const OwnerInfoForm = ({values, errors, handleChange, setFieldValue}) => {
  const app = useRealmApp();


  const [owner, setOwner] = useState({});
  const [isOwnerFoundModalOpen, setIsOwnerFoundModalOpen] = useState(false);
  const [isCreateOwnerModalOpen, setIsCreateOwnerModalOpen] = useState(false);

  const closeCreateOwnerModal = () => {
    setIsCreateOwnerModalOpen(false);
  }

  const closeOwnerFoundModal = () => {
    setIsOwnerFoundModalOpen(false);
  }


  const handleConfirm = () => {
    setOwner(owner);
    setIsOwnerFoundModalOpen(false);
    setFieldValue('ownerPhone', owner.phone)
    setFieldValue('ownerAddress3', owner.address3)
  }

  const handleSearch = () => {
    if (values.ownerName && values.ownerEmail && values.ownerAddress1 && !errors.ownerEmail) {
      searchOwner()
    }
  }

  const searchOwner = async () => {
    try {
      const searchValues = {
        ownerName: values.ownerName,
        ownerEmail: values.ownerEmail,
        ownerAddress: values.ownerAddress1
      }
      const data = await getOwnerByPersonalInfo(app, searchValues);

      if (data) {
        setIsOwnerFoundModalOpen(true);
        setOwner(data)
      } else {
        setIsOwnerFoundModalOpen(false);
        setIsCreateOwnerModalOpen(true)
      }
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <>
      <div>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8}>
            <Form.Item
              hasFeedback={true}
              showValidateSuccess={true}
              label="Owner Name"
              name="ownerName"
            >
              <Input
                name="ownerName"
                placeholder="Name exactly as on your horse’s breed registration papers"
                onChange={handleChange}
                onBlur={handleSearch}
              />
              <Tag style={{marginTop: "4px"}} color="red">
                *Must match Registration papers exactly*
              </Tag>
          </Form.Item>
        </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              hasFeedback={true}
              showValidateSuccess={true}
              label="Owner Email"
              name="ownerEmail"
            >
              <Input
                name="ownerEmail"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleSearch}
              />
            </Form.Item></Col>
          <Col xs={24} sm={8}>

            <Form.Item
              hasFeedback={true}
              showValidateSuccess={true}
              label="Owner Address Line 1"
              name="ownerAddress1"
            >
              <Input
                name="ownerAddress1"
                placeholder="Owner Street Address"
                onChange={handleChange}
                onBlur={handleSearch}
              />
            </Form.Item></Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8}>
        <Form.Item
          hasFeedback={true}
          showValidateSuccess={true}
          label="Owner Address Line 2"
          name="ownerAddress3"
        >
          <Input
            name="ownerAddress3"
            placeholder="Owner Address Line 2"
            onChange={handleChange}
          />
        </Form.Item></Col>
          <Col xs={24} sm={8}>
        <Form.Item
          value={values.ownerPhone}
          hasFeedback={true}
          showValidateSuccess={true}
          label="Owner Phone"
          name="ownerPhone"
        >
          <Input
            name="ownerPhone"
            placeholder="Owner Phone"
            onChange={handleChange}
          />
        </Form.Item></Col></Row>
      </div>

      <h4 style={{marginTop: '50px'}}>
        Please read and accept the {' '}
        <a href="/2025_Stallion_Enrollment_Rules_.pdf" target="_blank" rel="noopener noreferrer">
          Tomorrow’s Legends Stallion Enrollment Rules
        </a>
      </h4>
      <Form.Item
        label="Sign by checking:"
        name="signed"
        hasFeedback={true}
        showValidateSuccess={true}
      >
        <Checkbox type="checkbox" name="signed"/>
      </Form.Item>

      {/*<CreateOwnerModal*/}
      {/*  isCreateOwnerModalOpen={isCreateOwnerModalOpen}*/}
      {/*  closeCreateOwnerModal={closeCreateOwnerModal}*/}
      {/*  setOwner={setOwner}*/}
      {/*  values={values}*/}
      {/*  errors={errors}*/}
      {/*  handleConfirm={handleConfirm}*/}
      {/*/>*/}
      <OwnerFoundModal
        isOwnerFoundModalOpen={isOwnerFoundModalOpen}
        setIsOwnerFoundModalOpen={setIsOwnerFoundModalOpen}
        handleConfirm={handleConfirm}
        closeOwnerFoundModal={closeOwnerFoundModal}
        result={owner}
      />
    </>
  );
};


export default OwnerInfoForm;