import React from 'react';
import {Table} from "antd";

const ResultsTable = (props) => {



  const getRowClassName = (record) => {
    const place = record.place;

    const categoryRegex = {
      "1D": /^1[dD]-\d+.*$/,
      "2D": /^2[dD]-\d+.*$/,
      "3D": /^3[dD]-\d+.*$/,
      "4D": /^4[dD]-\d+.*$/,
      "Junior": /^\d+(st|nd|rd|th).*$/i
    };

    const category = Object.keys(categoryRegex).find(cat => categoryRegex[cat].test(place));

    switch (category) {
      case "1D":
        return "category-1d";
      case "2D":
        return "category-2d";
      case "3D":
        return "category-3d";
      case "4D":
        return "category-4d";
      case "Junior":
        return "category-junior";
      default:
        return "no-place";
    }
  };

  return (
    <Table
      key={props.selectedView}
      virtual
      rowKey={(record) => record._id}
      className="ant-table"
      pagination={false}
      dataSource={props.dataSource}
      columns={props.columns}
      loading={props.loading}
      rowClassName={props.selectedView !=='Earnings' && getRowClassName}
    />
  );
};

export default ResultsTable;