import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Card,
  Divider,
  List, message,
  Steps,
  Typography,
} from "antd";
import {Formik} from "formik";
import {Form, SubmitButton} from "formik-antd";

import SireInfoForm from "./SireInfoForm";
import OwnerInfoForm from "./OwnerInfoForm";
import MarketingInfoForm from "./MarketingInfoForm";
import FormReview from "./FormReview";
import SirePaymentForm from "../SirePaymentForm";
import axios from "axios";
//import {getSireByRegNum} from "../../services/sires";
import {useRealmApp} from "../../utils/RealmApp";
import {getSireEnrollment} from "../../services/sireEnrollments";

const SireEnrollmentIndex = () => {
  const {Title, Paragraph} = Typography;
  const [current, setCurrent] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [api, contextHolder] = message.useMessage();
  const [savedValues, setSavedValues] = useState(null);
  const [enrolledSireId, setEnrolledSireId] = useState(null);
  const ref = useRef(null);

  const app = useRealmApp()

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };


  const pricingData = ['$2500 = $2600'];


  useEffect(() => {
    const storedValues = localStorage.getItem('sireEnrollmentFormValues');

    if (storedValues) {
      setSavedValues(JSON.parse(storedValues));
    }
  }, []);

  const saveFormValues = (values) => {
    localStorage.setItem('sireEnrollmentFormValues', JSON.stringify(values));
  };

  const handleNextAndSave = (values, errors = {}) => {
    saveFormValues(values);
    if (!Object.entries(errors).length) {
      setCurrent(current + 1);
    }
  };

  const handleCreateEnrollment = async (values) => {
    try {
      const toUpperCaseIfString = (value) => (typeof value === 'string' ? value.toUpperCase() : value);
      const transformedValues = {};
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          transformedValues[key] = key !== 'country' ? toUpperCaseIfString(values[key]) : values[key];
        }
      }

      const result = await axios.post(`${process.env.REACT_APP_MANAGEMENT_API_URL}/sire-enrollment/enroll`, transformedValues)
      if (result.data.success) {
        const newSireId = result.data.enrollment?.insertedId || result.data.enrollment?.value?._id;
        const newEnrollment = await getSireEnrollment(app, newSireId);
        console.log(newEnrollment, 'newEnrollment')
        setEnrolledSireId(newSireId)
        setSavedValues(newEnrollment)
        handleNextAndSave(values);
      }
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Submit Form Failed",
        "Please try again later or reach out to the support team.",
        "bottomRight",
      );
    }
  }

  const handleBack = () => {
    setCurrent(current - 1);
  }
  const onStepChange = (value) => {
    setCurrent(value);
  };


  const items = [
    {
      title: '1',
      description: 'Stallion Information',
    },
    {
      title: '2',
      description: 'Owner Information',
    },
    {
      title: '3',
      description: 'Marketing Information',
    },
    {
      title: '4',
      description: 'Review',
    },
    {
      title: '5',
      description: 'Payment',
    },
  ]

  const initialValues = {
    // Step 1
    name: savedValues?.name || '',
    birthYear: savedValues?.birthYear || '',
    regNum: savedValues?.regNum || '',
    sireSire: savedValues?.sireSire || '',
    dam: savedValues?.dam || '',
    damSire: savedValues?.damSire || '',
    fee: savedValues?.fee || '',
    semenAvailability: savedValues?.semenAvailability || [],
    country: savedValues?.country || '',
    contact: savedValues?.contact || '',
    junior: savedValues?.junior || false,
    limited: savedValues?.limited || false,
    barrels: savedValues?.barrels || false,
    poles: savedValues?.poles || false,
    firstCropBirthYear: savedValues?.firstCropBirthYear || '',
    breedingReport: savedValues?.breedingReport || {},

    // Step 2
    ownerName: savedValues?.ownerName || '',
    ownerEmail: savedValues?.ownerEmail || '',
    ownerAddress1: savedValues?.ownerAddress1 || '',
    ownerAddress3: savedValues?.ownerAddress3 || '',
    ownerPhone: savedValues?.ownerPhone || '',
    signed: savedValues?.signed || false,

    // Step 3
    stationName: savedValues?.stationName || '',
    stationAddress1: savedValues?.stationAddress1 || '',
    stationAddress3: savedValues?.stationAddress3 || '',
    stationEmail: savedValues?.stationEmail || '',
    stationPhone: savedValues?.stationPhone || '',
    stationWebsite: savedValues?.stationWebsite || '',
    socialMedia: savedValues?.socialMedia || '',
    website: savedValues?.website || '',
    marketingParagraph: savedValues?.marketingParagraph || '',
    papers: savedValues?.papers || {},
    headPicture: savedValues?.headPicture || {},
    profilePicture: savedValues?.profilePicture || {},
    video: savedValues?.video || {},
  };

  const validate = async (values) => {
    const errors = {};
    if (current === 0) {
      if (!values.name) errors.name = 'Name is required';
      if (!values.birthYear) errors.birthYear = 'Birth Year is required';
      if (!values.regNum) {
        errors.regNum = 'Registration Number is required'
      }
      // else if (values.regNum) {
      //   const res = await getSireByRegNum(app, values.regNum);
      //   if (res) {
      //     errors.regNum = "Registration number already exists";
      //   }
      // }
      if (!values.sireSire) errors.sireSire = 'Sire is required';
      if (!values.dam) errors.dam = 'Dam is required';
      if (!values.damSire) errors.damSire = 'Dam Sire is required';
      if (!values.country) errors.country = 'Country is required';
      if (!values.semenAvailability) errors.semenAvailability = 'Semen Availability is required';
      if (!values.contact) errors.contact = 'Contact Person is required';
      if (!values.fee) errors.fee = 'Fee is required';
      if (values.junior && !values.firstCropBirthYear) errors.firstCropBirthYear = 'Year is required for all junior Stallions'

      if (values.limited && (!values.breedingReport || (values.breedingReport && Object.entries(values.breedingReport).length === 0))) {
        errors.breedingReport = 'Breeding Report is required'
      }
    }
    if (current === 1) {
      if (!values.ownerName) errors.ownerName = 'Name is required';
      if (!values.ownerEmail) {
        errors.ownerEmail = 'Email is required';
      } else if (!/^\S+@\S+\.\S+$/.test(values.ownerEmail)) {
        errors.ownerEmail = 'Invalid email address';
      }
      if (!values.ownerAddress1) errors.ownerAddress1 = 'Street Address is required';
      if (!values.ownerPhone) errors.ownerPhone = 'Owner Phone is required';
      if (!values.signed) errors.signed = 'Please review the document and sign by checking';
    }
    if (current === 2) {
      if (!values.stationName) errors.stationName = 'Stallion Station Name is required';
      if (values.stationEmail && !/^\S+@\S+\.\S+$/.test(values.stationEmail)) {
        errors.stationEmail = 'Invalid email address';
      }
      if (!values.marketingParagraph) errors.marketingParagraph = 'Marketing Paragraph is required';
      if (!values.papers || (values.papers && Object.entries(values.papers).length === 0)) errors.papers = 'Required'

    }
    return errors;
  }


  return (
    <>
      {!isStarted &&
        <Card>
          <Paragraph>
            At this time
            we only offer online payment via credit card, and charge a{" "}
            <strong>4% additional fee </strong> to cover the credit card
            processing fee.
          </Paragraph>
          <Divider orientation="center">Pricing with Fees</Divider>
          <List
            size="small"
            bordered
            dataSource={pricingData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
          <div style={{marginTop: "20px", textAlign: "center"}}>
            <Button
              size="large"
              onClick={() => {
                setTimeout(() => {
                  ref?.current?.scrollIntoView({behavior: "smooth"});
                }, 100);
                setIsStarted(true);
              }}>
              {!savedValues ? 'Start' : 'Continue'} Application
            </Button>
          </div>
        </Card>}


      {isStarted &&
        <>
          {contextHolder}
          <Title level={4} ref={ref}>
            Please use this form to enroll Stallions.
          </Title>

          <Card style={{minHeight: "50vh"}}>


            <Formik initialValues={initialValues}
                    validate={validate}
                    onSubmit={handleCreateEnrollment}>
              {({values, handleChange, errors, setFieldValue, setTouched, validateForm}) => (

                <div>
                  <div style={{margin: "20px 0 50px 0"}}>
                    <Steps
                      size="medium"
                      current={current}
                      //onChange={onStepChange}
                      items={items}
                      status={Object.entries(errors).length ? 'error' : ''}
                    />
                  </div>

                  <Form
                    layout="vertical"
                  >

                    {current === 0 &&
                      <SireInfoForm
                        handleChange={handleChange}
                        values={values}
                        setFieldValue={setFieldValue}
                      />}

                    {current === 1 &&
                      <OwnerInfoForm
                        setFieldValue={setFieldValue}
                        errors={errors}
                        values={values}
                      />}

                    {current === 2 &&
                      <MarketingInfoForm values={values} setFieldValue={setFieldValue}/>}
                    <Divider/>

                    {current === 3 &&
                      <>
                        <FormReview values={values} onStepChange={onStepChange}/>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: 'flex-end',
                            gap: "10px",
                          }}>
                          <Form.Item label="" name="submit">
                            <SubmitButton
                              size="large"
                            >
                              Enroll Stallion and Pay
                            </SubmitButton>
                          </Form.Item>
                        </div>
                      </>}

                    {(current === 0 ||
                        current === 1 ||
                        current === 2) &&
                      <div
                        style={{
                          display: "flex",
                          justifyContent: 'flex-end',
                          gap: "10px",
                        }}>
                        {current !== 0 &&
                          <Button
                            size="large"
                            onClick={handleBack}>
                            Back
                          </Button>}
                        {(current !== 4 || current !== 5) &&
                          <Button
                            size="large"
                            onClick={async () => {
                              const errors = await validateForm(values);
                              setTouched(Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}));

                              if (Object.keys(errors).length === 0) {
                                handleNextAndSave(values, errors);
                              }
                            }}>
                            Save and Next
                          </Button>}
                      </div>
                    }
                  </Form>
                </div>
              )}

            </Formik>
            {current === 4 &&
              <>
                <SirePaymentForm
                  onStepChange={() => setCurrent(3)}
                  data={savedValues}
                  sireName={savedValues?.name}
                  birthYear={savedValues?.birthYear}
                  regNum={savedValues?.regNum}
                  ownerName={savedValues?.ownerName}
                  ownerEmail={savedValues?.ownerEmail}
                  sireId={enrolledSireId}
                  applicationType='Enrollment'
                />
              </>
            }

          </Card>

        </>
      }

    </>
  );
};


export default SireEnrollmentIndex;