import React from 'react';
import {Button, Descriptions, Modal} from "antd";

const OwnerFoundModal = ({
                           isOwnerFoundModalOpen,
                           closeOwnerFoundModal,
                           handleConfirm,
                           result
                         }) => {
  return (
    <Modal
      title="Owner Found"
      open={isOwnerFoundModalOpen}
      onCancel={closeOwnerFoundModal}
      onOk={closeOwnerFoundModal}
      footer={
        [<Button onClick={closeOwnerFoundModal}>
          Cancel
        </Button>,
          <Button key="submit" onClick={handleConfirm}>
          Confirm & Continue
        </Button>,
        ]}
      width={"45rem"}
    >
      <Descriptions size="small" column={1} title="Owner Information">
        <Descriptions.Item label={<b>Name</b>}>
          {result?.name}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Address</b>}>
          {result?.address1}{' '}{result?.address3}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Email</b>}>
          {result?.email}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Phone</b>}>
          {result?.phone}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default OwnerFoundModal;