import React from 'react';
import {Layout} from "antd";

import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import OwnerNominatorInfoUpdateForm from "../modules/OwnerNominatorInfoUpdateForm";

const { Content } = Layout;

const OwnerNominatorInfoUpdatePage = (props) => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu />
      <BannerImage url="/Enrollment Search.png"/>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <OwnerNominatorInfoUpdateForm {...props}/>
        </div>
      </Content>
      <TLFooter/>
    </Layout>
);
};

export default OwnerNominatorInfoUpdatePage;