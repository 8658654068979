import React from 'react';
import {Button, Descriptions, Divider, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";

const {Title} = Typography;

const FormReview = ({values, onStepChange}) => {
  return (
    <>
      <Title level={4} style={{marginBottom: '30px'}}>Please review information and proceed to payment.</Title>
        <Descriptions
          size="small"
          column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
          title="Stallion Information">

          <Descriptions.Item label={<b>Name</b>}>
            {values?.name}
          </Descriptions.Item>
          <Descriptions.Item label={<b>Birth Year</b>}>
            {values?.birthYear}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Registration Number</b>}>
            {values?.regNum}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Sire</b>}>
            {values?.sireSire}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Dam </b>}>
            {values?.dam}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Dam Sire</b>}>
            {values?.damSire}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Fee</b>}>
            ${values?.fee}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Semen Availability</b>}>
            {values?.semenAvailability.map((item, i, arr) => <span style={{marginRight:'5px'}}>{i !== arr.length - 1 ? item + ', ' : item}</span>)}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Country</b>}>
            {values?.country}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Contact Person</b>}>
            {values?.contact}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Junior</b>}>
            {values?.junior ? 'Yes' : 'No'}
          </Descriptions.Item>

          <Descriptions.Item label={<b>First Crop Birth Year</b>}>
            {values?.firstCropBirthYear}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Limited</b>}>
            {values?.limited ? 'Yes' : 'No'}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Breeding Report</b>}>
            {values?.breedingReport?.name || 'Not Uploaded'}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Barrels</b>}>
            {values?.barrels ? 'Yes' : 'No'}
          </Descriptions.Item>

          <Descriptions.Item label={<b>Poles</b>}>
            {values?.poles ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
        <Button
          style={{marginTop: '20px'}}
          onClick={() => {
            onStepChange(0)
          }}>
          <EditOutlined/>
          Edit
        </Button>

      <Divider plain/>
      <Descriptions
        size="small"
        column={1}
        title="Owner Information">
        <Descriptions.Item label={<b>Owner Name</b>}>
          {values?.ownerName}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Owner Address</b>}>
          {values?.ownerAddress1}{' '}{values?.ownerAddress3}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Owner Email</b>}>
          {values?.ownerEmail}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Owner Phone</b>}>
          {values?.ownerPhone}
        </Descriptions.Item>
      </Descriptions>
      <Button
        style={{marginTop: '20px'}}
        onClick={() => {
          onStepChange(1)
        }}>
        <EditOutlined/>
        Edit
      </Button>

      <Divider plain/>
      <Descriptions
        size="small"
        column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
        title="Marketing Information">
        <Descriptions.Item label={<b>Station Name</b>}>
          {values?.stationName}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Address</b>}>
          {values?.stationAddress1}{' '}{values?.stationAddress3}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Email</b>}>
          {values?.stationEmail}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Phone</b>}>
          {values?.stationPhone}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Station Website</b>}>
          {values?.stationWebsite}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Stallion Page Website</b>}>
          {values?.website}
        </Descriptions.Item>
        <Descriptions.Item label={<b>Marketing Paragraph</b>}>
          {values?.marketingParagraph}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Papers</b>}>
          {values?.papers?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Head Picture</b>}>
          {values?.headPicture?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Profile Picture</b>}>
          {values?.profilePicture?.name || 'Not Uploaded'}
        </Descriptions.Item>

        <Descriptions.Item label={<b>Video</b>}>
          {values?.video?.name || 'Not Uploaded'}
        </Descriptions.Item>
      </Descriptions>
      <Button
        style={{marginTop: '20px'}}
        onClick={() => {
          onStepChange(2)
        }}>
        <EditOutlined/>
        Edit
      </Button>

    </>
  );
};

export default FormReview;