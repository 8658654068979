import React, {useState} from 'react';
import {AutoComplete, Checkbox, Form, Input, Select} from "formik-antd";
import {Button, Col, Divider, Row, Tag, Tooltip, Upload} from "antd";
import {useRealmApp} from "../../utils/RealmApp";
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {getEnrolledSireSires} from "../../services/enrolledSireSires";

const SireInfoForm = ({handleChange, values, setFieldValue}) => {
  const app = useRealmApp();


  const countryList = ['USA', "Canada", "Brazil", "European Union", "Other"]
  const [options, setOptions] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState((values?.breedingReport && Object.entries(values?.breedingReport).length) ? [values.breedingReport] : []);
  const [fileUpload, setFileUpload] = useState({});
  const [progress, setProgress] = useState(0);


  const getAllSireSires = async () => {
    let sires = await getEnrolledSireSires(app);
    const sireOptions = sires.map(sire => ({value: sire.name}));
    setOptions(sireOptions)
  };

  React.useEffect(() => {
    getAllSireSires();
  }, []);

  const uploadImage = async (options) => {
    const {onError} = options;

    setFileUpload({});
    try {
      const {onSuccess, file, onProgress} = options;

      const fmData = new FormData();
      const config = {
        headers: {"Content-Type": file.type},

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({percent: (event.loaded / event.total) * 100});
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: { prefix: "breedingReport", name: file.uid, filetype: file.type },
        },
      );

      await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: {"Content-Type": file.type},
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      onError({err});
    }
  };
  const handleOnChange = ({fileList}) => {
    setDefaultFileList(fileList);
    setFieldValue('breedingReport', fileList[0])
  };

  return (
    <>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Stallion's Registered Name"
            name="name"
            hasFeedback={true}
            showValidateSuccess={true}
            rules={[{required: true}]}
          >
            <Input name="name" placeholder="Stallion Name"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Birth Year"
            name="birthYear"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="birthYear" placeholder="Year"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Registration Number"
            name="regNum"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="regNum" placeholder="Registration Number"/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Sire"
            name="sireSire"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <AutoComplete
              name='sireSire'
              placeholder="Sire Name"
              allowClear
              onChange={handleChange}
              onSelect={handleChange}
              options={options}
              filterOption={(inputValue, option) =>
                option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Dam"
            name="dam"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="dam" placeholder="Dam"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Dam Sire"
            name="damSire"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="damSire" placeholder="Dam Sire"/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Fee"
            name="fee"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="fee" placeholder="Fee"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Semen Availability"
            name="semenAvailability"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Semen Availability"
              name="semenAvailability"
              defaultValue={countryList}
            >
              {countryList.map(item => (
                <Select.Option
                  key={item}
                  value={item}
                  id={item}
                >
                  {item}
                </Select.Option>
              ))}

            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Country"
            name="country"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Select
              placeholder="Country"
              name="country"
            >
              {countryList.map(item => (
                <Select.Option
                  key={item}
                  value={item}
                  id={item}
                >
                  {item}
                </Select.Option>
              ))}

            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Contact Person"
            name="contact"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="contact" placeholder="Contact Person"/>
          </Form.Item>
        </Col>
      </Row>

      <Divider/>

      <Row gutter={[32, 0]}>
        <Col xs={12} sm={6}>
          <Form.Item
            label={
            <span>
                Junior
                <Tooltip
                  title="TL defines a Junior Sire as having 3 or fewer foal crops of age to compete. The oldest foals would be 6 or under."
                  color="gray"
                >
                  <Tag color="gray" style={{ marginLeft: 8 }}>?</Tag>
                </Tooltip>
              </span>
          }
            name="junior"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Checkbox
              name='junior'
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={6}>
          {values?.junior &&
            <Form.Item
              name="firstCropBirthYear"
              label="Birth Year of First Foal Crop"
            >
              <Input
                style={{width: "100px"}}
                name="firstCropBirthYear" placeholder="Year"
              />
            </Form.Item>
          }
        </Col>
        <Col xs={12} sm={6}>
          <Form.Item
            label={
              <span>
                Limited
                <Tooltip
                  title="TL defines a Limited Sire as one that breeds 15
or fewer mares per year.  Please upload a copy of your breeding report showing 15
or fewer mares bred, either the year prior or you may use a report from 4 years
prior."
                  color="gray"
                >
                  <Tag color="gray" style={{ marginLeft: 8 }}>?</Tag>
                </Tooltip>
              </span>
            }
            name="limited"
          >
            <Checkbox
              name='limited'
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={6}>
          {values?.limited &&
            <Form.Item label="Breeding Report" name="breedingReport">
              <Upload
                accept="*"
                name="breedingReport"
                customRequest={uploadImage}
                onChange={handleOnChange}
                defaultFileList={defaultFileList}
                className="image-upload-grid"
              >
                {defaultFileList.length >= 1 ? null : (
                  <>
                    <Button icon={<UploadOutlined/>}>Upload</Button>
                    <Tag style={{marginBottom: "4px"}} color="red">
                      *Required for all limited Stallions.
                    </Tag>
                  </>
                )}
              </Upload>
            </Form.Item>}
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={32} sm={32}>
          <h4
            style={{margin:"30px 0"}}
          >Would you like your enrollment dollars dedicated to barrel or pole
            payouts?</h4>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={12} sm={12}>
          <Form.Item
            label="Barrels"
            name="barrels"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Checkbox name='barrels'/>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12}>
          <Form.Item
            label="Poles"
            name="poles"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Checkbox name='poles'/>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SireInfoForm;