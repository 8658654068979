import React, { useState, useEffect, useRef } from "react";
import {Button, Modal, Row, Col, Divider, Descriptions, Skeleton} from "antd";

import "../App.css";
import useResponsive from "../hooks/useResponsive";
import {formatMoney} from "../utils/utils";
import {getPayoutsSummary} from "../services/payouts";

const FoalFoundModal = ({
  isFoalModalOpen,
  closeFoalModal,
  openFoalModal,
  owner,
  result,
  dam,
  sire,
  handleOwnerChange,
  handleUpdateFoalInfo,
  handleConfirm,
  autoClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const buttonRef = useRef(null);
  const [horseTotalEarnings, setHorseTotalEarnings] = useState('');

  const getHorseEarnings = async () => {
    try {
      setLoading(true)
      const params = {
        id: result?._id?.toString(),
        collection: 'foal',
        list: true
      };
      const res = await getPayoutsSummary(params, false);

      if (res?.summary && res.summary?.length) {
        const totalResult = res.summary.reduce((acc, curr) => acc + curr.total, 0);
        setHorseTotalEarnings(totalResult);
      } else {
        console.log('No earnings found in the response', res);
      }

    } catch (error) {
      console.error('Error fetching horse earnings:', error);
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    if(result?._id) {
      getHorseEarnings()
    }
  }, [result]);
  const { isXs, isSm } = useResponsive();

  const isMobile = isXs || isSm;

  useEffect(() => {
    if (autoClick && isFoalModalOpen) {
      buttonRef?.current?.click();
    }
  }, [isFoalModalOpen]);

  // Form modal
  const handleShowFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const horseConfirmed = () => {
    setIsFormModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isFoalModalOpen}
        onCancel={closeFoalModal}
        cancelText={"Try Again"}
        okText={"Contact Us"}
        onOk={closeFoalModal}
        footer={[
          <Button key="submit" onClick={handleConfirm}>
            Confirm & Close
          </Button>,
        ]}
        width={"45rem"}
        style={{position: isMobile? 'static': 'relative', padding: isMobile? '0px': '' }}
      >
        <div>
          <h4 style={{ margin: "1rem" }}>
            Please confirm horse owner information is correct. If any
            information needs updating, please select the relevant option below
          </h4>
        </div>

        <Divider style={{...(isMobile
                  ? { margin: "1rem 0", padding: "0" }
                  : {  }
          )}}/>

        {/* Two columns on the bottom */}
        <Row justify="center">
          <Col
              style={{
                ...(isMobile
                        ? { paddingBottom: "1rem" }
                        : { padding: "1rem" }
                ),
              }}
              xs={24} sm={24} md={12} lg={12}
          >
            {/* Text in the left column */}
            <Descriptions size="small" column={1} title="Horse Information">
              <Descriptions.Item label={<b>Name</b>}>
                {result?.name}
              </Descriptions.Item>

              <Descriptions.Item label={<b>Sire</b>}>
                {sire?.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Dam</b>}>
                {dam?.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Year of Birth</b>}>
                {result?.yob}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Sex</b>}>
                {result?.sex?.toUpperCase()}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Horse Total Earnings</b>}>
                {loading ? <Skeleton.Button active size={"small"}/> : formatMoney(horseTotalEarnings)}
              </Descriptions.Item>
            </Descriptions>
            <Button
              // key="email"
              onClick={handleUpdateFoalInfo}
              // href="https://www.tomorrowslegendsllc.com/infoupdate"
              // target="_blank"
              type="primary"
              style={{
                marginTop: "15px",
              }}
            >
              Update Horse Info
            </Button>
          </Col>
          <Col
              style={{
                ...(isMobile
                        ? { borderTop: "1px solid #ccc", paddingTop: "1rem" }
                        : { borderLeft: "1px solid #ccc", padding: "1rem" }
                ),
              }}
              xs={24} sm={24} md={12} lg={12}
          >
            {/* Text in the right column */}
            <Descriptions
              size="small"
              column={1}
              title="Owner Information"
              style={{}}
            >
              <Descriptions.Item label={<b>Name</b>}>
                {owner.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Address</b>}>
                {owner.address1}
              </Descriptions.Item>
              <Descriptions.Item label={<b>City, State, Zipcode</b>}>
                {owner.address3}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Phone</b>}>
                {owner.phone}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Email</b>}>
                {owner.email}
              </Descriptions.Item>
            </Descriptions>
            <Button
              style={{
                marginTop: "15px",
              }}
              key="owner_change"
              type="secondary"
              onClick={handleOwnerChange}
              ref={buttonRef}
            >
              Change Horse Ownership
            </Button>
            {/*<Button*/}
            {/*  style={{*/}
            {/*    marginTop: "15px",*/}
            {/*  }}*/}
            {/*  href="https://www.tomorrowslegendsllc.com/infoupdate"*/}
            {/*  target="_blank"*/}
            {/*  type="secondary"*/}
            {/*>*/}
            {/*  Update Owner Info*/}
            {/*</Button>*/}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FoalFoundModal;
