export const getSidePotsByEventId = async (app, eventId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sidePotCollection = mongo.db("legends").collection("sidepots");
    return await sidePotCollection.aggregate([
      {$match: {eventId: eventId}}
    ]);
  } catch (e) {
    console.log("Error fetching side pots:", e);
    return [];
  }
};