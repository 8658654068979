import axios from "axios";

export const getPayoutsSummary = async (params, grouped = true) => {
  try {
    let url = `${process.env.REACT_APP_MANAGEMENT_API_URL}/payout/summary?grouped=${grouped}`;

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && value !== null) {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    }

    const response = await axios.get(url);
    return response.data;
  } catch (e) {
    throw e;
  }
};

