import  {useState} from 'react';
import {Button, Modal, notification} from "antd";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import * as React from "react";
import axios from "axios";

const EmailProofOfEnrollmentModal = ({
                            isSendReceiptModalOpen,
                            closeSendReceiptModal,
                            foalId
                          }) => {

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  console.log(foalId, 'receiptData')
  console.log(isLoading, 'loading')


  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/proof-of-enrollment`,
        {foalId: foalId.toString(), email}
      );
      openNotificationWithIcon(
        "success",
        "Email Sent Successfully",
        `Proof of Enrollment Sent to ${email}.`,
        "bottomRight",
      );
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Send receipt failed",
        "We are having trouble sending the proof of enrollment. Please reach out to the support team. ",
        "bottomRight",
      );
    }finally {
      setIsLoading(false);
      setEmail('')
    }
    closeSendReceiptModal()
  }

  return (
    <>
      {contextHolder}
      <Modal
        title="Please Enter Your Email"
        open={isSendReceiptModalOpen}
        onCancel={closeSendReceiptModal}
        centered
        confirmLoading={isLoading}
        onOk={handleSubmit}
        footer={[
          <Button key="submit" onClick={handleSubmit} loading={isLoading}>
            Send
          </Button>
        ]}
      >
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validate={() => {
            const errors = {};
            if (!email) {
              errors.email = "Please enter an email";
            }
            return errors;
          }}>
          {() => (
            <Form layout="vertical">
              <Form.Item
                label="Email"
                name="email"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input
                  name="email"
                  onChange={handleChange}
                  value={email}
                />
              </Form.Item>
            </Form>
          )}
        </Formik>

      </Modal>
    </>
  );
};

export default EmailProofOfEnrollmentModal;