import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Descriptions,
  Divider,
  notification,
  Spin,
  Typography,
  Upload,
  Row,
  Col,
  Checkbox,
  Progress,
  Tag,
} from "antd";
import { Formik } from "formik";
import { Input, Form, Select } from "formik-antd";
import { useLocation, useParams } from "react-router-dom";
import { useRealmApp } from "../utils/RealmApp";
import axios from "axios";
import InvalidLink from "../components/InvalidLink";
import { CheckCircleOutlined } from "@ant-design/icons";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const OwnerNominatorInfoUpdateForm = () => {
  const [info, setInfo] = useState({
    city: '',
    state: '',
    zipcode: '',

  });
  const [loading, setLoading] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [progress, setProgress] = useState(0);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const countryLabels = ["USA", "Canada", "Brazil", "European Union", "Other"];
  const country = countryLabels.map((c) => {
    return {
      label: c,
      value: c,
    };
  });

  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const { entity, id } = useParams();

  const app = useRealmApp();
  const mongo = app.currentUser.mongoClient("mongodb-atlas");
  const db = mongo.db("legends");

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };

  async function getInfo() {
    try {
      setLoading(true);
      const result = await db
        .collection(entity + "s")
        .findOne({ _id: { $oid: id } });
      let obj = await axios.get(
        `${
          process.env.REACT_APP_MANAGEMENT_API_URL
        }/update/${entity}?token=${token}&id=${result._id?.toString()}`,
        {
          updatePayload: {
            id: result._id?.toString(),
          },
        }
      );

      if (obj.data.tax) result.tax = obj.data.tax;
      setInfo(result);
      
      //regex to parse out address lines for input boxes
      const regex = /(.+?), (\w+) (\d{5})/;
      const match = obj.data.owner?.address3.match(regex) || obj.data.nominator?.address3.match(regex);
      if (match) {

        setInfo(prevInfo => ({
          ...prevInfo,
          city: match[1],
          state: match[2],
          zipcode: match[3],
          address3: ''
        }));
        
      } else {
        console.error('Could not format address');
      }

      setIsLinkValid(true);
    } catch (err) {
      console.log(err, 'err')
      setIsLinkValid(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (entity) {
      getInfo();
    }
  }, [entity]);

  const uploadImage = async (options) => {
    const { onError } = options;

    setFileUpload({});
    try {
      const { onSuccess, file, onProgress } = options;

      const fmData = new FormData();
      const config = {
        headers: { "Content-Type": file.type },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/upload`,
        {
          filename: file.uid,
        }
      );

      await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const params = {
        name: values.name?.trim().toUpperCase(),
        email: values.email?.trim().toUpperCase(),
        phone: values.phone?.trim().toUpperCase(),
        address1: values.address1?.trim().toUpperCase(),
        address3: ((values.city?.trim().toUpperCase()) + ", " + (values.state?.trim().toUpperCase()) + " " + (values.zipcode?.trim().toUpperCase())),
        country: values.country?.trim().toUpperCase(),
        taxId: values.taxId?.trim(),
        ein: values.ein
      };
      await axios.put(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/update/${entity}?token=${token}`,
        params
      );

      await axios.post(
        `${
          process.env.REACT_APP_MANAGEMENT_API_URL
        }/tax/${entity}/${id}`,
        {
          identification: params.taxId,
          tag:  params.ein ? "ein" : "ssn",
          w9: { key: fileUpload.uid, name: "W9", filename: fileUpload.name  }
        });

      await getInfo();
      setIsSuccess(true);
      setTimeout(() => {
        window.location.href = `/registration-lookup`;
      }, 6000);
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Error",
        `Information update failed. Error: ${e.message}`,
        "bottomRight"
      );
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    name: info?.name || "",
    email: info?.email || "",
    phone: info?.phone || "",
    address1: info?.address1 || "",
    city: info?.city || "",
    state: info?.state || "",
    zipcode: info?.zipcode || "",
    country: info?.country || "",
    taxId: info?.taxId || "",
    ssn: info?.ssn || false,
    ein: info?.ein || false,
    w9: info?.w9 || ""

  };

  const Success = () => {
    return (
      <Card bordered={false}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircleOutlined
            style={{ color: "green", fontSize: "30px", marginBottom: "20px" }}
          />
          <Title level={4}>
            Thank you for submitting Horse information update!
          </Title>
          <Title level={5}>Information Updated Successfully.</Title>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Descriptions
            size="small"
            column={1}
            title={`${
              entity === "owner" ? "Owner" : "Nominator"
            }: ${info.name.toUpperCase()}`}
          >
            {/*<Descriptions.Item label={<b>Name</b>}>*/}
            {/*  {info.name}*/}
            {/*</Descriptions.Item>*/}

            <Descriptions.Item label={<b>Address</b>}>
              {info.address1}
              <br />
              {info.city + ", " + info.state + " " + info.zipcode}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Country</b>}>
              {info.country}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Phone</b>}>
              {info.phone}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Email</b>}>
              {info.email}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Spin />
        </div>
      ) : isSuccess ? (
        <Success />
      ) : isLinkValid ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
              errors.email = "Enter a valid email";
            }
            if (!values.phone) {
              errors.phone = "Phone is required";
            }
            if (!values.address1) {
              errors.address1 = "Address 1 is required";
            }
            if (!values.city) {
              errors.city = "City is required";
            }
            if (!values.state) {
              errors.state = "State is required";
            }
            if (!values.zipcode) {
              errors.zipcode = "Zipcode is required";
            }

            if(!values.ein && !values.ssn) {
              errors.taxId = "You must select EIN or SSN"
            }
            console.log(values.ein, values.ssn)
            // if(!values.taxId.match(/^([0][1-6]|1[0-6]|(?!70)[27][0-7]|(?!31)[35][0-9]|[468][0-8]|9[0-589])?\d{7}$/)) {
            //   errors.taxId = "Please provide a 9 digit tax id number"
            // }

            // optional dashes validation
            if (!values.taxId.match(/^(\d{3}-?\d{2}-?\d{4}|\d{2}-?\d{7})$/)) {
              errors.taxId = "Invalid Tax ID. Please enter a valid 9-digit SSN (XXX-XX-XXXX or XXXXXXXXX) or EIN (XX-XXXXXXX or XXXXXXXXX).";
            }

            // no special characters at all
            // if (!values.taxId.match(/^\d{9}$/)) {
            //   errors.taxId = "Invalid Tax ID. Please enter a valid 9-digit SSN or EIN ensuring there are no dashes or spaces.";
            // }

            if(defaultFileList && !defaultFileList.length) {
              errors.w9 = ""
            }

            // else {
            //   const isValidFormat = /^[^,\s]+(?:[ -][^,\s]+)?(?:,\s*[^,\s]+)?(?:\s+|\s*,\s*)?\d{5}$/.test(values.address3.trim());
            //   if (!isValidFormat) {
            //     errors.address3 = 'City, State, Zip Code must include all three parts. Example: City, ST 12345';
            //   }
            // }
            if (!values.country) {
              errors.country = "Country is required";
            }
            return errors;
          }}
        >
          {({ values, handleChange, setFieldValue }) => (
            <div className="container">
              <Title level={4}>
                Please use this form to update {entity} information.
              </Title>
              <Card title="Update Information" bordered={false}>
                <Form
                  layout="vertical"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Form.Item label="Name" name="name">
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Name"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Email" name="email">
                    <Input
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone">
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                    />
                  </Form.Item>
                  <Form.Item label="Address 1" name="address1">
                    <Input
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      placeholder="Address 1"
                    />
                  </Form.Item>
                  <Form.Item label="City" name="address3">
                    <Input
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      placeholder="City"
                    />
                  </Form.Item>
                  <Form.Item label="State" name="state">
                    <Input
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      placeholder="State"
                      maxLength={2}
                    />
                  </Form.Item>
                  <Form.Item label="Zip Code" name="zipcode">
                    <Input
                      name="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      placeholder="Zip Code"
                    />
                  </Form.Item>
                  <Form.Item label="Country" name="country">
                    <Select
                      options={country}
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      name="country"
                      placeholder="Country"
                    />
                  </Form.Item>
                  <Card title="Tax Information" bordered={false}></Card>
                  <p>Please provide a valid Employer Identification Number or valid Social Security Number. This number should match what is used on your W9 form.</p>
                  <Row justify="left">
                  <Col span={8}>
                  <Form.Item
                  label="SSN"
                  name="ssn"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={handleChange}
                >
                  <Checkbox type="checkbox" name="ssn"
                  checked={values.ssn}
                            onChange={() => {
                              setFieldValue('ssn', !values.ssn);
                              setFieldValue('ein', false);
                            }}
                  />
                </Form.Item>
                </Col>
                <Col span={8}>
                <Form.Item
                  label="EIN"
                  name="ein"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={handleChange}
                >
                  <Checkbox type="checkbox" name="ein"
                            checked={values.ein}
                            onChange={() => {
                              setFieldValue('ein', !values.ein);
                              setFieldValue('ssn', false);
                            }}
                  />
                </Form.Item>
                </Col>
                </Row>
                  <Form.Item label="Tax Identification #" name="taxId">
                    <Input.Password
                      name="taxId"
                      value={values.taxId}
                      onChange={handleChange}
                      placeholder="EIN or SSN"
                    />
                  </Form.Item>
                  <Form.Item label="W9" name="w9">
                    <Upload
                      accept="*"
                      name="w9"
                      customRequest={(value) => uploadImage(value, "29")}
                      onChange={(value) => handleOnChange("w9", value)}
                      defaultFileList={defaultFileList}
                      className="image-upload-grid"
                    >
                      {defaultFileList && defaultFileList.length >= 1 ? null : (
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      )}
                    </Upload>
                    {defaultFileList && !defaultFileList.length && (
                      <Tag
                        icon={<ExclamationCircleOutlined />}
                        style={{ marginTop: "4px", whiteSpace: "normal" }}
                        color="warning"
                      >
                        You must upload your w9 tax form to update information
                      </Tag>
                    )}
                    {progress > 0 ? <Progress percent={progress} /> : null}
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit Information Change Form
                  </Button>
                </Form>
              </Card>
            </div>
          )}
        </Formik>
      ) : (
        <InvalidLink />
      )}
    </>
  );
};

export default OwnerNominatorInfoUpdateForm;
