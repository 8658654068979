export const createHorseChange = async (app, change) => {
  try {
    if (!change.foal) {
      throw new Error('Foal id is missing');
    }
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const horseChangesCollection = mongo.db("legends").collection("horseChanges");
    const insertedId = await horseChangesCollection.insertOne(change);
    return insertedId;
  } catch (error) {
    throw error;
  }
};