export const getEnrolledSireSires = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const enrolledSireSires = mongo.db("legends").collection("enrolledSireSires");

    const sires = await enrolledSireSires.find({}, { sort: { name: 1 } });
    return sires;
  } catch (error) {
    console.error("Error fetching enrolled Sire Sires:", error);
  }
};
