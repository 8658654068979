import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Descriptions, Skeleton, Table} from "antd";
import {useReactToPrint} from "react-to-print";
import "./styles.css";
import EmailProofOfEnrollmentModal from "./EmailProofOfEnrollmentModal";
import InfoUpdateRequestModal from "./InfoUpdateRequestModal";
import {getPayoutsSummary} from "../services/payouts";
import {formatMoney} from "../utils/utils";
const NominatorInformation = (props) => {
  const [isSendReceiptModalOpen, setIsSendReceiptModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);
  const [nominatorTotalEarning, setNominatorTotalEarning] = useState(0)
  const [payouts, setPayouts] = useState([])
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const [data, setData] = useState( []);


  const getNominatorEarnings = async () => {
    try {
      setPayoutsLoading(true)
      const params = {
        id: props?.info?._id?.toString(),
        collection: 'nominator',
        list: true
      };
      const res = await getPayoutsSummary(params, false);
      if (res?.summary && res.summary?.length) {
        const total = res?.summary.reduce((acc, cur) => acc + cur.total, 0);
        setNominatorTotalEarning(total);
      }
      if (res?.payouts && res.payouts?.length) setPayouts(res.payouts)
    } catch (error) {
      console.log('Error fetching horse earnings:', error);
    } finally {
      setPayoutsLoading(false);
    }
  };

  useEffect(() => {
    if(props?.info?._id) {
      getNominatorEarnings();
    }
  }, []);

  const getFoalEarnings = (foalId) => {
    const totalEarnings = payouts
    .filter(payout => payout?.foal?._id?.toString() === foalId?.toString())
    .reduce((acc, payout) => {
      return acc + (payout?.placement?.money || 0);
    }, 0);

    return formatMoney(totalEarnings);
  };


  const closeSendReceiptModal = () => {
    setIsSendReceiptModalOpen(false);
    setSelectedRowData(null);
  };

  const openSendReceiptModal = (rowData, type) => {
    setIsSendReceiptModalOpen(true);
    setSelectedRowData(rowData)
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Registration Number",
      dataIndex: "regNum",
      key: "regNum",
    },
    {
      title: "Birth Year",
      dataIndex: "yob",
      key: "yob",
    },
    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "Dam",
      dataIndex: "dam",
      key: "dam",
    },
    {
      title: "Sire",
      dataIndex: "sire",
      key: "sire",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Earnings",
      dataIndex: "earnings",
      key: "earnings",
    },
    {
      title: "Proof Of Enrollment",
      dataIndex: "proofOfEnrollment",
      key: "proofOfEnrollment",
      render: (_, record) => (
        <Button
          key={`${record.foalId}-receipt`}
          onClick={() => openSendReceiptModal(record)}
          type="primary"
        >
          Send Proof Of Enrollment
        </Button>
      ),
    },
    {
      title: "Information",
      dataIndex: "updateHorseInfo",
      key: "updateHorseInfo",
      render: (_, record) => (
        <Button
          key={`${record.foalId}-update`}
          href={`/foal-update?regnum=${record.regNum}`}
          target="_blank"
          type="primary"
        >
          Update Horse Info
        </Button>
      ),
    },
  ];

  const dataSource = props?.info?.horses.map((horse) => {
    return {
      foalId: horse?._id,
      name: horse.name,
      regNum: horse.regNum,
      yob: horse.yob,
      sex: horse.sex,
      dam: horse?.dams[0]?.name || "Unknown",
      sire: horse?.sires[0]?.name || "Unknown",
      owner: horse?.owners[0]?.name || "Unknown",
      earnings: payoutsLoading ? <Skeleton.Button active size={"small"}/> : getFoalEarnings(horse?._id)
    };
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (<>
      <div ref={componentRef} id="print">
        {props.info?.horses?.length > 0 ? (
          <div>
            <p>{`${props.info?.horses?.length} horse${
              props.info?.horses?.length > 1 ? "s" : ""
            } found.`}</p>
            <Button className="no-print" onClick={handlePrint}>
              Print Horses
            </Button>
            <Button
              onClick={() => setIsUpdateInfoModalOpen(true)}
              type="primary"
              style={{
                marginBottom: "15px",
                marginLeft: "5px",
              }}
            >
              Update Nominator Info
            </Button>
            <Descriptions size="small" column={1}>
                  <Descriptions.Item label={<b>Nominator Total Earnings</b>}>
                    {payoutsLoading ? <Skeleton.Button active size={"small"}/> : formatMoney(nominatorTotalEarning)}
                  </Descriptions.Item>
            </Descriptions>
            <InfoUpdateRequestModal
              entity={"Nominator"}
              info={props.info}
              isModalOpen={isUpdateInfoModalOpen}
              closeModal={() => setIsUpdateInfoModalOpen(false)}
            />
            <Table
              className="ant-table"
              dataSource={dataSource}
              pagination={false}
              columns={columns}
              rowKey={(record) => record?.foalId}
            />
          </div>
        ) : (
          <p>No horses are associated with this nominator</p>
        )}
      </div>
      <EmailProofOfEnrollmentModal
        closeSendReceiptModal={closeSendReceiptModal}
        openSendReceiptModal={openSendReceiptModal}
        isSendReceiptModalOpen={isSendReceiptModalOpen}
        foalId={selectedRowData?.foalId}
      />
    </>
  );
};
export default NominatorInformation;
