export const getSireRenewal = (app, id) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sireRenewals = mongo.db("legends").collection("sireRenewals");

    return sireRenewals.aggregate([
      {
        $lookup: {
          from: "sires",
          localField: "sire",
          foreignField: "_id",
          as: "sire",
        },
      },
      {
        $match:
          {_id: {$oid: id}}
      }, {
        $unwind: {
          path: "$sire",
          'preserveNullAndEmptyArrays': true
        }
      }
    ])

  } catch (e) {
    throw e;
  }
}

export const updateSireRenewal = (app, id, params) => {
  try{
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sireRenewals = mongo.db("legends").collection("sireRenewals");

    return sireRenewals.findOneAndUpdate({_id: id}, {$set: {...params}})

  }catch (e) {
    throw e;
  }
}