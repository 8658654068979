import React from 'react';
import {Layout} from "antd";
import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import FoalInfoUpdateForm from "../modules/FoalInfoUpdateForm";

const {Content} = Layout;


const FoalInfoUpdatePage = () => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu/>
      <BannerImage url="/Enrollment Search.png" />
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <FoalInfoUpdateForm/>
        </div>
      </Content>
      <TLFooter/>
    </Layout>
  );
};

export default FoalInfoUpdatePage;