import React from 'react';
import { Layout } from "antd";
import TLMenu from "../layout/TLMenu";
import {BannerImage} from "../components/BannerImage";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import EarningForm from "../modules/EarningForm";

const { Content } = Layout;

const EarningPage = () => {
  return (
    <Layout className="layout"
            style={{
              minHeight: "100vh",
            }}
    >
      <TLMenu />
      <BannerImage url="/Owner Change.png"/>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <TLBreadCrumb />
        <div className="site-layout-content">
          <EarningForm/>
        </div>
      </Content>
      <TLFooter />
    </Layout>
  );
};

export default EarningPage;