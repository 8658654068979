import React from "react";
import { Layout } from "antd";
import { EntryForm } from "../modules/EntryForm";

import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import { BannerImage } from "../components/BannerImage";

const { Content } = Layout;

const EntryPage = (props) => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu />
      <BannerImage url="/race entry pic.png" />
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <EntryForm {...props} />
        </div>
      </Content>
      <TLFooter />
    </Layout>
  );
};

export default EntryPage;
