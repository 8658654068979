import React, { useState } from "react";
import { Button, Modal, Row, Col, Divider } from "antd";

import "../App.css";
import { EntryErrorForm } from "./EntryErrorForm";

const FoalNotFoundModal = ({ isModalOpen, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  // Form modal
  const handleShowFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handleFormSubmit = () => {
    handleCloseFormModal();
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        cancelText={"Try Again"}
        okText={"Contact Us"}
        footer={false}
      >
        <Modal
          open={isFormModalOpen}
          onCancel={handleCloseFormModal}
          footer={null}
        >
          <EntryErrorForm />
        </Modal>
        <div>
          <h2 style={{ margin: "1rem" }}>
            We do not have record of that registration number in our system.
          </h2>
        </div>

        <Divider />

        <Row justify="center">
          <Col
            style={{
              flex: 1,
              padding: "1rem",
            }}
          >
            <p>
              If you believe this to be an error, for example the horse was
              enrolled before papers were completed by AQHA and never updated
              with us, please contact us here.
            </p>
            <Button key="email" onClick={handleShowFormModal} type="primary">
              Contact Us
            </Button>
          </Col>
          <Col
            style={{
              flex: 1,
              borderLeft: "1px solid #ccc",
              padding: "1rem",
            }}
          >
            <p>
              If you need to enroll your horse please do so here then return to
              complete your event entry. Please allow 24 hours for enrollment
              processing before attempting your entry.
            </p>
            <Button
              key="enrollment"
              type="primary"
              href="/foal-enrollment"
            >
              Enroll Horse
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FoalNotFoundModal;
