export const getAllRiders = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const ridersCollection = mongo.db("legends").collection("riders");
    const ridersData = await ridersCollection.find();
    return { ridersData };
  } catch (error) {
    console.error("Error fetching riders:", error);
  }
};

export function getRider(app, id) {
  const mongo = app.currentUser.mongoClient("mongodb-atlas");
  const ridersCollection = mongo.db("legends").collection("riders");
  return ridersCollection.findOne({ _id: id });
}

export function createRider(app, params) {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const ridersCollection = mongo.db("legends").collection("riders");
    return ridersCollection.insertOne(params);
  } catch (error) {
    console.log(error, 'error')
  }
}
