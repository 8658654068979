import { Typography } from "antd";
const { Title, Text, Paragraph } = Typography;

const PaymentDisclosure = () => {
  return (
    <>
      <Paragraph>
        1. To be eligible for TL Payouts, enrollment fee must be paid in full,
        enrollment form and copy of foal papers on file with TL office.
      </Paragraph>
      <Paragraph>
        2. Enrollments due March 15th of the horse’s yearling year.
      </Paragraph>
      <Paragraph>
        3. A copy of the foal’s breed registration papers must be included with
        the enrollment.
      </Paragraph>
      <Paragraph>
        4. Receipt of this Enrollment form does not guarantee enrollment. If
        enrollment is not granted, all fees will be refunded. If Enrollment is
        granted, receipt listing horse, sire, year of birth, date of enrollment,
        and nominator information will be emailed to the nominator email address
        on file.
      </Paragraph>
      <Paragraph>
        5. By Signing this form and enrolling Tomorrow’s Legends, I agree to
        allow this horse, any jockey competing with said horse, minor,
        professional, or amateur and/or my image and/or likeness to be used by
        Tomorrow’s Legends for advertising purposes.
      </Paragraph>
    </>
  );
};

export default PaymentDisclosure;
