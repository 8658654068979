import React, {useEffect, useState} from 'react';
import {Button, Modal, notification, Radio} from "antd";
import axios from "axios";
import {CheckCircleOutlined} from "@ant-design/icons";

const InfoUpdateRequestModal = ({
                                  isModalOpen,
                                  closeModal,
                                  entity,
                                  info
                                }) => {
  const [contactType, setContactType] = useState('email')
  const [contactValue, setContactValue] = useState(info?.[contactType]);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [success, setSuccess] = useState(false);

  const openNotificationWithIcon = (type, title, description, placement, duration) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration
    });
  };

  const onContactTypeChange = (e) => {
    setContactType(e.target.value)
  }

  useEffect(() => {
    setContactValue(info?.[contactType])
  }, [contactType, info]);

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const payload = {
        type: entity.toLowerCase(),
        id: info._id,
        method: contactType
      }
      await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/tokens`,
        payload
      )
      setSuccess(true)
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Request failed",
        "Please try again later ot reach out to the support team.",
        "bottomRight",
        6000
      );
    } finally {
      setLoading(false);
    }

  }

  const FormContent = () => {
    return (
      <>
        <h4>
          Please choose your preferred method
        </h4>
        <Radio.Group onChange={onContactTypeChange} value={contactType}>
          <Radio value={'email'}>Email</Radio>
          <Radio value={'phone'}>SMS</Radio>
        </Radio.Group>
        <div style={{marginTop: '20px'}}>
          <h4><b>{contactType.toUpperCase()}:</b> {contactValue ? contactValue : `No information on file`}</h4>
        </div>
      </>
    )
  }

  const Success = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CheckCircleOutlined style={{color: "green", fontSize: "30px", marginBottom: "10px"}}/>
        <div>
          Link sent to <b>{contactValue}</b>. Please check your {contactType === 'email' ? 'inbox' : 'phone message'} and follow the instructions.
        </div>
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Modal
        centered
        title={`Request ${entity} Information Update`}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={closeModal}
        okText={!success ? "Send Link" : "Close"}
        cancelText={"Cancel"}
        width={"45rem"}
        footer={[
          <Button key="submit" onClick={success ? closeModal : handleSubmit} loading={loading}>
            {!success ? "Send Link" : "Close"}
          </Button>,
        ]}
      >{!success
        ? <FormContent/>
        : <Success/>
      }
      </Modal>
    </>
  );
};

export default InfoUpdateRequestModal;