export const updateSireEnrollment = async (app, id, params) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sireEnrollments = mongo.db("legends").collection("sireEnrollments");

    return sireEnrollments.findOneAndUpdate({_id:  id}, {$set: {...params}})
  } catch (error) {
    console.error("Error updating Sire Enrollment:", error);
  }
};

export const getSireEnrollment = (app, id) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sireRenewals = mongo.db("legends").collection("sireEnrollments");
    return sireRenewals.findOne({_id: {$oid: id}});
  } catch (e) {
    throw e;
  }
}

