export const getOwner = async (app, ownerId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const ownersCollection = mongo.db("legends").collection("owners");

    const owner = await ownersCollection.findOne({ _id: { $oid: ownerId } });

    return owner;
  } catch (error) {
    console.error("Error fetching owner:", error);
  }
};
