import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Card, Col, Row, notification, Tooltip, Tag} from "antd";
import {Formik} from "formik";
import {Form, Select} from "formik-antd";
import {getAllSortedSires} from "../services/sires";
import {useRealmApp} from "../utils/RealmApp";
import {useReactToPrint} from "react-to-print";
import ResultsTable from "../components/ResultsTable";
import {getPayoutsSummary} from "../services/payouts";
import {SyncOutlined} from "@ant-design/icons";
import {formatDate} from "../utils/utils";

const currentYear = new Date().getFullYear() + 1;
const yearList = [];
for (let year = currentYear; year >= 2021; year--) {
  yearList.push(year.toString());
}
const EarningForm = () => {

  const app = useRealmApp();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 0,
    });
  };
  const [sires, setSires] = useState([]);
  const [selectedSire, setSelectedSire] = useState(null);
  const [sireName, setSireName] = useState("");
  const [selectedYear, setSelectedYear] = useState('');
  const [earningData, setEarningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const earningTotalCalculate = earningData?.reduce((acc, curr) => acc + curr.total, 0);
  const earningTotal = parseFloat(earningTotalCalculate).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
  let title = "Earnings"
  if (earningTotalCalculate !== undefined && !isNaN(earningTotalCalculate)) {
    if (earningTotalCalculate !== 0 && selectedYear) {
      title = `Total Sire Earnings for ${sireName} year ${selectedYear}: ${earningTotal}`;
    }
  } else {
    console.log("Earning data is not valid");
  }


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchData = async () => {
    try {
      const sires = await getAllSortedSires(app);

      setSires(sires);

      let data;
      if (selectedSire) {
        const params = {
          year: selectedYear,
          sortOption: 'total',
          sire: selectedSire.toString()
        }
         data = await getPayoutsSummary(params);
        setEarningData(data)

        const sire = sires.find((s) => s._id.toString() == selectedSire);
        if (sire?.name) setSireName(sire.name)
      }
    } catch (error){
      openNotificationWithIcon(
        "error",
        "Fetch Data",
        `Failed to fetch data ${error.message}`,
        "bottomRight"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true)
      fetchData();
  }, [selectedYear, selectedSire]);

  useEffect(() => {
    if (selectedSire && selectedYear) {
      setShow(true);
      setTimeout(() => {
        componentRef?.current?.scrollIntoView({behavior: "smooth"});
      }, 100);
    }
  }, [selectedSire, selectedYear]);


  const handleSireChange = (e) => {
    setShow(false);
    const selectedSire = sires.find((s) => s._id.toString() === e);
    if (selectedSire) {
      setSelectedSire(selectedSire._id)
    }
  }

  const handleYearChange = (value) => {
    setShow(false);
    setSelectedYear(value);
  }

  const initialValues = {
    sireId: selectedSire?._id,
    year: selectedYear
  }

  const columns = [
    {
      title: "Horse",
      dataIndex: 'horse',
      key: "horse",
    },
    {
      title: "Rider",
      dataIndex: 'rider',
      key: "rider",
    },
    {
      title: "Owner",
      dataIndex: 'ownerName',
      key: "ownerName",
    },
    {
      title: "Nominator",
      dataIndex: 'nominatorName',
      key: "nominatorName",
    },
    {
      title: "Amount Won",
      dataIndex: 'total',
      key: "total",
      render: (total) => parseFloat(total).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    },
    {
      title: "Placing",
      dataIndex: 'place',
      key: "place",
    },
    {
      title: "Side Pot",
      dataIndex: ["sidepot", "class"],
      key: "sidePot",
    },
    {
      title: "Event",
      dataIndex:  ["event", "name"],
      key: "eventName",
    },
    {
      title: "Event Date",
      dataIndex: ["event", "date"],
      key: "eventDate",
      render: (date) => {
        return formatDate(date)
      }
    },
  ]

  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
        <Col span={24} xs={32} xl={32}>
          <Card title="Select From Options Below To See Earnings" bordered={false}>
            <Formik
              initialValues={initialValues}
              onSubmit={()=>{}}
            >
              {(formik) => (
                <Form
                  layout="vertical"
                  labelCol={{span: 6}}>
                  <Row gutter={32}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item
                        label='Sire'
                        name='sireId'
                      >
                        <Select name='sireId'
                                style={{width: "100%"}}
                                placeholder={'Select Sire...'}
                                showSearch
                                filterOption={(input, option) => {
                                  return (
                                    option.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toString().toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(value) => {
                                  handleSireChange(value);
                                }}>
                          {sires.map((sire) => (
                            <Select.Option
                              key={sire._id.toString()}
                              id={sire._id.toString()}
                              value={sire.id}
                            >
                              {sire.name} - {sire.yearEnrolled || ""}
                            </Select.Option>
                          ))}

                        </Select>

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Tooltip
                          title="Year Represents Event Season, Not
                        Calendar Year."
                          color={"gray"}
                        >
                          <Tag color="gray">?</Tag>
                        </Tooltip>
                        <Form.Item
                          label={"Year"}
                          name="year"
                          style={{ flex: 1}}
                        >
                          <Select
                            name="year"
                            style={{ width: '100%' }}
                            placeholder={'Select Year...'}
                            onChange={(value) => {
                              handleYearChange(value);
                            }}
                            disabled={!selectedSire}
                          >
                            {yearList?.map(year =>
                              <Select.Option
                                key={year}
                                value={year}>
                                {year}
                              </Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} xl={2} style={{textAlign: 'end'}}>
                      <Form.Item
                        label=" "
                        name="clear"
                      ><Tooltip title='Clear All Filters'  color={"gray"}>
                        <SyncOutlined style={{fontSize: '24px', fontWeight: 'bold', color: 'gray'}} onClick={() => {
                          formik.resetForm();
                          setSelectedSire(null)
                          setSelectedYear('')
                          setShow(false)
                        }}/>
                      </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{textAlign: 'end', margin: '0 auto auto'}}>
                      {show && <Button className="no-print" onClick={handlePrint}>
                        Print Results
                      </Button>}
                    </Col>
                  </Row>
                </Form>
              )}

            </Formik>
          </Card>
          <div ref={componentRef} id="print1" style={{marginTop: "20px"}}>
            {show && <Card title={title} bordered={false}>
              <ResultsTable
                columns={columns}
                dataSource={earningData}
                loading={loading}
                selectedView={'Earnings'}
              />
            </Card>}
          </div>
        </Col>
      </Row>

    </>
  );
};

export default EarningForm;