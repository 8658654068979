import React, {useState} from 'react';
import {Button, Card} from "antd";
import {EditOutlined} from "@ant-design/icons";
import SireRenewalEditModal from "../../components/SireRenewalEditModal";
import {Form, SubmitButton} from "formik-antd";
import {Formik} from "formik";
import SireRenewalInfo from "./SireRenewalInfo";

const SireRenewalForm = ({renewalValues, onSubmit, skipPayment}) => {

  const [isSireRenewalEditModalOpen, setIsSireRenewalEditModalOpen] = useState(false);
  const [fileLists, setFileLists] = useState({
    papers: (renewalValues.papers && Object.entries(renewalValues.papers).length) ? [renewalValues.papers] : [],
    headPicture: (renewalValues.headPicture && Object.entries(renewalValues.headPicture).length) ? [renewalValues.headPicture] : [],
    profilePicture: (renewalValues.profilePicture && Object.entries(renewalValues.profilePicture).length) ? [renewalValues.profilePicture] : [],
    video: (renewalValues.video && Object.entries(renewalValues.video).length) ? [renewalValues.video] : [],
    breedingReport: (renewalValues.breedingReport && Object.entries(renewalValues.breedingReport).length) ? [renewalValues.breedingReport] : [],
  });


  const closeSireRenewalModal = () => {
    setIsSireRenewalEditModalOpen(false)
  }

  const initialValues = {
    sireId: renewalValues?._id,
    name: renewalValues?.name || '',
    birthYear: renewalValues?.birthYear || '',
    regNum: renewalValues?.regNum || '',
    sireName: renewalValues?.sire?.name || '',
    damName: renewalValues?.dam?.name || '',
    damSireName: renewalValues?.damSire?.name || '',

    ownerName: renewalValues?.owner?.name || '',
    ownerAddress1: renewalValues?.owner?.address1 || '',
    ownerAddress3: renewalValues?.owner?.address3 || '',
    ownerEmail: renewalValues?.owner?.email || '',
    ownerPhone: renewalValues?.owner?.phone || '',
    contact: renewalValues?.contact || '',
    semenAvailability: renewalValues?.semenAvailability || [],
    country: renewalValues?.country || '',
    stationName: renewalValues?.standingFacilities?.name || '',
    stationAddress1: renewalValues?.standingFacilities?.address1 || '',
    stationAddress3: renewalValues?.standingFacilities?.address3 || '',
    stationEmail: renewalValues?.standingFacilities?.email || '',
    stationPhone: renewalValues?.standingFacilities?.phone || '',
    stationWebsite: renewalValues?.standingFacilities?.website || '',
    website: renewalValues?.website || '',
    marketingParagraph: renewalValues?.marketingParagraph || '',
    socialMedia: renewalValues?.socialMedia || '',
    junior: renewalValues?.junior || false,
    limited: renewalValues?.limited || false,
    barrels: renewalValues?.barrels || false,
    poles: renewalValues?.poles || false,
    firstCropBirthYear: renewalValues?.firstCropBirthYear || '',
    breedingReport: fileLists.breedingReport[0],
    papers: fileLists.papers[0],
    headPicture: fileLists.headPicture[0],
    profilePicture: fileLists.profilePicture[0],
    video: fileLists.video[0],
    renewalSigned: renewalValues.renewalSigned || false
  };


  const validate = (values) => {
    const errors = {};
    if (!values.country) errors.country = 'Country is required';
   // if (!values.semenAvailability?.length) errors.semenAvailability = 'Semen Availability is required';
    if (!values.contact) errors.contact = 'Contact Person is required';
    if (values.junior && !values.firstCropBirthYear) errors.firstCropBirthYear = 'Year is required for all junior Stallions'


    if (values.limited && (values.breedingReport && Object.entries(values.breedingReport).length === 0)) {
      errors.breedingReport = 'Breeding Report is required'
    }

    if ((values.papers && Object.entries(values.papers).length === 0)) errors.papers = 'Required'

    if (!values.stationName) errors.stationName = 'Stallion Station Name is required';
    if (values.stationEmail && !/^\S+@\S+\.\S+$/.test(values.stationEmail)) {
      errors.stationEmail = 'Invalid email address';
    }
    if (!values.marketingParagraph) errors.marketingParagraph = 'Marketing Paragraph is required';
    if (!values.renewalSigned) errors.renewalSigned = 'Please review the document and sign by checking';
    return errors;
  }


  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >

      {({values, handleChange, resetForm, errors, setFieldValue, setTouched}) => (
        <Form
          layout="vertical"
        >
          <>
            <Card
              title="Please review Stallion information, make edits if necessary, upload files and proceed to payment.">

              <SireRenewalInfo values={values} errors={errors}/>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 5,
                  marginTop: '20px'
                }}
              >
                <SireRenewalEditModal
                  isModalOpen={isSireRenewalEditModalOpen}
                  closeModal={closeSireRenewalModal}
                  values={values}
                  handleChange={handleChange}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  fileLists={fileLists}
                  setFileLists={setFileLists}
                />
                <Button
                  size="large"
                  onClick={() => {
                    setIsSireRenewalEditModalOpen(true);
                    setTouched({
                      contact: true,
                      semenAvailability: true,
                      country: true,
                      firstCropBirthYear: true,
                      breedingReport: true,
                      stationName: true,
                      marketingParagraph: true,
                      renewalSigned: true
                    });
                  }}>
                  <EditOutlined/>
                  Edit Information
                </Button>
                <SubmitButton
                  size="large">
                  {skipPayment? 'Submit Renewal' : "Pay Renewal Fee"}
                </SubmitButton>
              </div>
            </Card>

          </>
        </Form>
      )}
    </Formik>
  );
};

export default SireRenewalForm;