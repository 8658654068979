import {Card, Layout, Typography} from "antd";
import React from "react";
import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import {useLocation} from "react-router-dom";
import {CheckCircleOutlined} from "@ant-design/icons";

const {Content} = Layout;
const {Title, Paragraph} = Typography;

const TLReceipt = () => {
  const location = useLocation();
  const props = location?.state;


  return (
    <Layout className="layout">
      <TLMenu/>
      <BannerImage url="/race entry pic.png"/>

      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <TLBreadCrumb/>
        {props && (
          <Card>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "50px", gap: 30}}>
              <CheckCircleOutlined style={{color: "green", fontSize: "30px"}}/>
              <Title level={4}>
                Your Sire {props.applicationType} has been submitted for {props.sireName}.
              </Title>
              <div className="site-layout-content">

                <Paragraph>
                  {" "}
                  You will receive a receipt for your accepted payment. Our team
                  will review your Sire {props.applicationType} and get started on the approval
                  process. You can print this page for your records.
                </Paragraph>
                <Paragraph>
                  Please direct any questions to enrollments@tomorrowslegendsllc.com
                </Paragraph>

                <Title level={4}>{props.applicationType} information: </Title>
                <Title level={5}>Total Amount paid: ${props.totalPrice}</Title>
                <Paragraph>Registration #: {props.regNum}</Paragraph>
                <Paragraph>Sire Name: {props.sireName}</Paragraph>
                <Paragraph>Birth Year: {props.birthYear}</Paragraph>

              </div>
            </div>
          </Card>
          )}
      </Content>
      <TLFooter/>
    </Layout>
);
};
export default TLReceipt;
