import { Layout } from "antd";
import React from "react";

import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import Header from "../components/Header";
const { Content } = Layout;

const HomePage = (props) => {
  return (
    <Layout className="layout">
      <TLMenu />
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content"></div>
        <Header />
      </Content>
      <TLFooter />
    </Layout>
  );
};
export default HomePage;
