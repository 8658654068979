import { useEffect, useState } from "react";
import { useRealmApp } from "../utils/RealmApp";
import { getAllRiders } from "../services/riders";
import { useField } from "formik";
import { Input, AutoComplete } from "antd";

export const RiderSelect = ({
  handleRiderInputChange,
  name,
  handleRiderChange,
}) => {
  const app = useRealmApp();
  const [field] = useField(name);

  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [riders, setRiders] = useState([]);

  useEffect(() => {
    fetchRiders(app);
  }, []);

  function fetchRiders(app) {
    getAllRiders(app).then(({ ridersData }) => {
      let parsedRiders = ridersData.map((rider) => ({
        value: rider.name,
        name: rider.name,
        id: rider._id.toString(),
      }));
      setRiders(parsedRiders);
    });
  }

  const handleInputChange = (value) => {
    setInputValue(value);
    if (value.trim() === "") {
      setFilteredOptions([]);
      return;
    }
    const filtered = riders.filter((rider) =>
      rider.name.toLowerCase().startsWith(value.toLowerCase().trim())
    );
    if (filtered.length === 0) {
      handleRiderInputChange(value);
      setFilteredOptions([]);
    } else {
      setFilteredOptions(filtered);
    }
  };

  const onSelect = (name, option) => {
    handleRiderChange(name, option);
  };

  return (
    <AutoComplete
      options={filteredOptions}
      value={inputValue}
      onChange={handleInputChange}
      onSelect={onSelect}
      placeholder="Rider Name"
      filterOption={false}
    >
      <Input {...field} />
    </AutoComplete>
  );
};
