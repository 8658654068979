import React from "react";
import {Layout} from "antd";
import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import ResultsForm from "../modules/ResultsForm";
import TLBreadCrumb from "../layout/TLBreadCrumb";

const {Content} = Layout;

const ResultsPage = () => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu/>
      <BannerImage url="/Enrollment Search.png"/>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <TLBreadCrumb />
          <ResultsForm/>
        </div>
      </Content>
      <TLFooter/>
    </Layout>
  )
}

export default ResultsPage;